<script setup>
import { ref, computed } from "vue";
import BlogsCard from "@components/blogs/BlogsCard.vue";

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const categories = ref([
    { id: 1, name: "All Blogs" },
    { id: 2, name: "Technology" },
    { id: 3, name: "News" },
    { id: 4, name: "Insights" },
    { id: 5, name: "Business" },
    { id: 6, name: "Product" }
])

const selectedCategory = ref("");

// blogs
const blogs = ref([]);

const getBlogs = async () => {
    try {
        const res = await axios(`/blogs/api/all`);
        blogs.value = res.data;
    } catch (error) {
        console.error(error);
    }
}

getBlogs();

const filteredBlogs = computed(() => {
    let items = blogs.value;
    if (items.length < 1) return [];

    if(selectedCategory.value === "All Blogs"){
        selectedCategory.value = "";
    }

    if(selectedCategory.value){
        items = items.filter(item => item.category.category_name == selectedCategory.value);
    }

    const groupedItems = [];
    for (let i = 0; i < items.length; i += 6) {
        groupedItems.push(items.slice(i, i + 6));
    }

    return groupedItems;
})
</script>

<template>
    <div class="flex flex-col">
        <!-- banner -->
        <div class="relative flex items-center justify-center 2xl:h-[900px] lg:h-[750px] h-[600px]">
            <div class="flex flex-col items-center justify-center md:gap-4 gap-6 max-w-[55rem] text-center max-sm:p-2">
                <h1 class="md:text-[64px] text-[40px] leading-tight font-extrabold bg-gradient-to-r from-[#E1251B] to-[#E37C0D] bg-clip-text text-transparent">
                    Stay Informed, Engaged, and Connected.
                </h1>
                <span class="text-[#8F8F8F] md:text-[24px] text-[20px]">
                    Uncover Events, Trends, and Transformative Insights within the dynamic and ever-evolving technological landscape.
                </span>
            </div>

            <!-- float figures -->
            <img :src="`/images/figures/particles-left.png`" class="absolute left-0 top-0"/>
            <img :src="`/images/figures/particles-right.png`" class="absolute right-0 -bottom-20"/>
            <img :src="`/images/figures/circle-blue.png`" class="absolute -right-[20rem] -top-[20rem]"/>
            <img :src="`/images/figures/circle-blue.png`" class="absolute -left-[20rem] -bottom-[20rem]"/>
        </div>
        <!-- blogs -->
        <div class="flex flex-col bg-gradient-to-r from-[#0A9EDF] to-[#31549D] md:p-[5rem] p-[1rem] z-[2]">
            <div class="bg-smicos-secondary hover:bg-smicos-secondary/90 rounded-full py-2 px-4 w-[10rem] cursor-pointer">
                <select 
                    @change="($event) => selectedCategory = $event.target.value"
                    class="text-white font-semibold appearance-auto w-full appearance-none outline-none cursor-pointer"
                >
                    <option 
                        v-for="category in categories" 
                        :value="category.name"
                        :key="category.id"
                        class="bg-white"
                    >
                        {{ category.name }}
                    </option>
                </select>
            </div>
            <div v-if="filteredBlogs.length > 0">
                <swiper
                    :slides-per-view="1"
                    :pagination="true"
                    :space-between="30"
                    :modules="modules"
                    :loop="true"
                    class="w-full"
                >
                    <swiper-slide v-for="(group, index) in filteredBlogs" :key="index">
                        <div class="flex flex-wrap justify-between items-center md:gap-4 md:px-[1.5rem]">
                            <div v-for="blog in group" :key="blog.id">
                                <BlogsCard 
                                    :blog="blog"
                                />
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
            <span 
                v-else
                class="font-bold text-white text-center w-full text-2xl py-10"   
            >
                No blogs posted
            </span>
        </div>
    </div>
</template>