<script setup>
import { ref, reactive } from "vue";
import {
    MapPinIcon,
    PhoneIcon,
    MailIcon
} from "@components/icons";

import AppointmentSetModal from "@/pages/contact-us/AppointmentSetModal.vue";

// Utils
const validateRules = (event) => {
    const pattern = /^[0-9]*$/;
    if (!pattern.test(event.target.value)) {
        event.target.value = event.target.value.replace(/[^0-9]/g, "");
    }
}

const formData = reactive({
    company_name: "",
    full_name: "",
    email: "",
    contact_no: "",
    subject: "",
    message: ""
});

const errors = ref({});

const submitInquiry = async () => {
    try {
        await window.axios.post(`/send-inquiry`, formData)
    } catch (error) {
        console.error(error);
        console.log(error);
        errors.value = error.response.data.errors;
    }
}

// Smicos connect modal
const appointmentModal = ref(false);
</script>

<template>
    <div class="flex flex-col pt-[7.5rem]">
        <!-- banner -->
        <div class="relative flex items-center justify-center xs:h-[600px] h-[400px]">
            <div class="flex flex-col items-center justify-center gap-4 max-w-[55rem] text-center p-4">
                <div class="md:text-[60px] text-[40px] leading-tight font-extrabold">
                    <span class="bg-gradient-to-r from-[#E1251B] to-[#E37C0D] bg-clip-text text-transparent">Get Personalized Guidance</span>
                </div>
                <h1 class="text-[#8F8F8F] md:text-[24px] text-[20px]">
                    Tailored 1-on-1 to help you navigate Smicos and achieve success effortlessly.
                </h1>
            </div>

            <!-- float figures -->
            <img :src="`/images/figures/particles-left.png`" class="absolute left-0 top-0 z-1"/>
            <img :src="`/images/figures/particles-right.png`" class="absolute right-0 -bottom-20 z-1"/>
            <img :src="`/images/figures/circle-blue.png`" class="absolute -right-[20rem] -top-[20rem] z-1"/>
            <img :src="`/images/figures/circle-blue.png`" class="absolute -left-[20rem] -bottom-[20rem] z-1"/>
        </div>

        <!-- Unlock new possibilities -->
        <div class="flex w-full md:py-[2.5rem] p-[1rem] bg-gradient-to-r from-[#F2CD59] to-[#E37C0D] z-[2]">
            <div class="flex justify-center">
                <div class="flex xl:flex-row flex-col justify-between 2xl:gap-0 md:gap-10 gap-4 w-full xl:max-w-[95%] md:max-w-[80%]">
                    <div class="flex flex-col gap-4 2xl:w-[55%] w-full">
                        <img
                            :src="`/images/solutions/sections/smicos-booking.png`"
                            class="w-full"
                            alt="smicos dashboard"
                        >
                    </div>
                    <div class="2xl:w-[40%] w-full">
                        <div class="flex flex-col gap-2 md:mt-[5rem]">
                            <span class="md:text-[26px] text-[18px] font-extrabold text-white pb-[1rem]">
                                Unlock New Possibilities for Your Business
                            </span>
                            <span class="text-white md:text-lg">
                                Schedule a one-on-one session with us today and discover how Smicos can propel your business forward. Whether you're seeking an in-depth exploration of our platform, a refresher on digital strategies, or expert guidance tailored to your needs, this session is designed to help you succeed.
                            </span>
                        </div>
                        <div class="flex flex-col gap-2 md:mt-[5rem] text-[#FFF]">
                            <span class="md:text-[26px] text-[18px] font-extrabold pb-[1rem]">
                                What’s in it for you?
                            </span>
                            <span class="md:text-lg">
                                Our dedicated Smicos team will work with you to identify how Smicos can elevate your operations, optimize campaigns, and drive growth. We’re here to provide insights into product features, solutions, and much more.
                            </span>
                            <span class="md:text-lg">
                                Let’s connect and craft your next success story, one conversation at a time!
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Information Section -->
        <div class="flex flex-col items-center justify-center bg-gradient-to-r from-[#31549D] to-[#0A9EDF] md:p-[3rem] p-[2rem] z-[2]">
            <div class="flex justify-center w-full">
                <div class="flex md:flex-row flex-col items-center md:gap-20 gap-10 md:max-w-[80%] w-full">
                    <div class="w-full">
                        <div class="font-extrabold md:text-[38px] text-[34px] md:text-left text-left bg-gradient-to-r from-[#E37C0D] to-[#F2CD59] bg-clip-text text-transparent md:w-[30rem] leading-tight">
                            Get a FREE Personalized Onboarding with Smicos Connect!
                        </div>
                    </div>
                    <div class="w-full">
                        <div class="flex justify-center w-full">
                            <div class="flex justify-center md:w-[430px]">
                                <button
                                    @click="appointmentModal = true"
                                    class="bg-smicos-primary rounded-md py-6 md:px-[5rem] px-[2rem] text-white font-bold md:text-[20px] text-[18px]"
                                >
                                    Book An Appointment
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Google connect -->
        <AppointmentSetModal 
            v-if="appointmentModal"
            @close="appointmentModal = false"
        />
    </div>
</template>