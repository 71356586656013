import { defineStore, acceptHMRUpdate } from "pinia";

import { ref, reactive } from "vue";

export const useAuthStore = defineStore("Auth", () => {

    const auth = reactive({});

    return {
        auth
    };
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
