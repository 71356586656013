<script setup>
import { ref, computed } from "vue";
import {
    ArrowUpRightIcon,
    StarIcon,
    ClockIcon,
    CheckListIcon
} from "@components/icons";

import Card from "@components/includes/Card.vue";
import EventsCard from "@components/events/EventsCard.vue";

const lessons = [
    {
        "id": 1,
        "title": "Lesson 1",
        "content": "Test",
        "banner": "/images/homepage/cards/banner.png"
    },
    {
        "id": 2,
        "title": "Lesson 2",
        "content": "Test",
        "banner": "/images/homepage/cards/banner.png"
    },
    {
        "id": 3,
        "title": "Lesson 3",
        "content": "Test",
        "banner": "/images/homepage/cards/banner.png"
    },
    {
        "id": 4,
        "title": "Lesson 4",
        "content": "Test",
        "banner": "/images/homepage/cards/banner.png"
    },
]

const videos = [
    {
        "id": 1,
        "title": "Lesson 1",
        "content": "Test",
        "banner": "/images/homepage/cards/banner.png"
    },
    {
        "id": 2,
        "title": "Lesson 2",
        "content": "Test",
        "banner": "/images/homepage/cards/banner.png"
    },
    {
        "id": 3,
        "title": "Lesson 3",
        "content": "Test",
        "banner": "/images/homepage/cards/banner.png"
    },
    {
        "id": 4,
        "title": "Lesson 4",
        "content": "Test",
        "banner": "/images/homepage/cards/banner.png"
    },
    {
        "id": 5,
        "title": "Lesson 5",
        "content": "Test",
        "banner": "/images/homepage/cards/banner.png"
    },
    {
        "id": 6,
        "title": "Lesson 6",
        "content": "Test",
        "banner": "/images/homepage/cards/banner.png"
    },
    {
        "id": 7,
        "title": "Lesson 7",
        "content": "Test",
        "banner": "/images/homepage/cards/banner.png"
    },
    {
        "id": 8,
        "title": "Lesson 8",
        "content": "Test",
        "banner": "/images/homepage/cards/banner.png"
    },
    {
        "id": 9,
        "title": "Lesson 9",
        "content": "Test",
        "banner": "/images/homepage/cards/banner.png"
    },
]
</script>

<template>
    <div class="flex flex-col">
        <!-- banner -->
        <div class="relative flex items-center justify-center 2xl:h-[900px] lg:h-[750px] h-[600px]">
            <div class="flex flex-col items-center justify-center gap-4 max-w-[55rem] text-center">
                <h1 class="text-[60px] leading-tight font-extrabold">
                    Master your Bot Creating skills with <span class="bg-gradient-to-r from-[#E1251B] to-[#E37C0D] bg-clip-text text-transparent">Smicos Academy</span>
                </h1>
                <span class="text-[#8F8F8F] text-[24px]">
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </span>
            </div>

            <!-- float figures -->
            <img :src="`/images/figures/particles-left.png`" class="absolute left-0 top-0"/>
            <img :src="`/images/figures/particles-right.png`" class="absolute right-0 -bottom-20"/>
            <img :src="`/images/figures/circle-blue.png`" class="absolute -right-[20rem] -top-[20rem]"/>
            <img :src="`/images/figures/circle-blue.png`" class="absolute -left-[20rem] -bottom-[20rem]"/>
        </div>
        <!-- advertising -->
        <div class="flex justify-center bg-gradient-to-r from-[#0A9EDF] to-[#31549D] p-20 z-[2]">
            <div class="flex items-start justify-center gap-14">
                <div class="flex flex-col justify-center max-w-[18rem] w-full gap-6 text-white">
                    <div class="relative">
                        <div
                            class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
                        >
                            <svg
                                class="w-4 h-4 text-gray-500"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 20"
                            >
                                <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                />
                            </svg>
                        </div>
                        <input
                            type="search"
                            class="block w-full pl-10 text-sm text-gray-900 border p-2.5 border-gray-300 rounded-full bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                            placeholder="Search lesson"
                        />
                    </div>
                    <span 
                        v-for="index in 5"
                        class="text-white text-[20px] pb-2 border-b-[2px]"
                    >
                        {{ `Category ${index}` }}
                    </span>
                </div>
                <div class="flex flex-wrap justify-center gap-10">
                    <div v-for="video in videos">
                        <EventsCard
                            :event="video"
                        />
                    </div>
                    <a
                        href="/events/upcoming/view"
                        class="bg-[#0A9EDF] hover:bg-[#0A9EDF]/80 w-[90%] rounded-full p-2 text-center text-white font-bold text-[20px]"
                    >
                        Load More
                    </a>
                </div>
            </div>
        </div>
       <!-- track progress -->
        <div class="flex justify-center gap-20 p-20">
            <div class="flex items-start justify-between w-full gap-20 max-w-[80%]">
                <div class="flex flex-col max-w-[25rem]">
                    <div class="font-extrabold leading-tight text-[45px] bg-gradient-to-r from-[#E1251B] to-[#E37C0D] bg-clip-text text-transparent">
                        Track Your Progress
                    </div>
                </div>
                <!-- Form -->
                <div class="flex-1 flex flex-col gap-6 p-10 rounded-md border shadow-md max-w-[50rem]">
                    <span class="font-extrabold leading-tight text-[25px] bg-gradient-to-r from-[#E1251B] to-[#E37C0D] bg-clip-text text-transparent mb-8">
                        Create an Account
                    </span>
                    <div class="flex items-center gap-10 w-full">
                        <div class="flex flex-col gap-2 w-full">
                            <span>
                                First name
                            </span>
                            <input type="text" placeholder="First name" class="border-b p-2 rounded-md w-full">
                        </div>
                        <div class="flex flex-col gap-2 w-full">
                            <span>
                                Last name
                            </span>
                            <input type="text" placeholder="Last name" class="border-b p-2 rounded-md w-full">
                        </div>
                    </div>
                    <div class="flex items-center gap-10 w-full">
                        <div class="flex flex-col gap-2 w-full">
                            <span>
                                Company name
                            </span>
                            <input type="text" placeholder="Company name" class="border-b p-2 rounded-md w-full">
                        </div>
                        <div class="flex flex-col gap-2 w-full">
                            <span>
                                Position
                            </span>
                            <input type="text" placeholder="Position" class="border-b p-2 rounded-md w-full">
                        </div>
                    </div>
                    <div class="flex flex-col gap-2 w-full">
                        <span>
                            Nature of business
                        </span>
                        <input type="text" placeholder="Nature of business" class="border-b p-2 rounded-md w-full">
                    </div>
                    <div class="flex items-center gap-10 w-full">
                        <div class="flex flex-col gap-2 w-full">
                            <span>
                                Email
                            </span>
                            <input type="text" placeholder="Email" class="border-b p-2 rounded-md w-full">
                        </div>
                        <div class="flex flex-col gap-2 w-full">
                            <span>
                                Contact number
                            </span>
                            <input type="text" placeholder="Contact number" class="border-b p-2 rounded-md w-full">
                        </div>
                    </div>
                    <div class="flex flex-col gap-2 w-full">
                        <span>
                            Password
                        </span>
                        <input type="password" placeholder="Password" class="border-b p-2 rounded-md w-full">
                    </div>
                    <div class="flex flex-col gap-2 w-full">
                        <span>
                            Confirm password
                        </span>
                        <input type="password" placeholder="Confirm password" class="border-b p-2 rounded-md w-full">
                    </div>
                    <div class="flex items-center gap-4 mt-8">
                        <input type="checkbox">
                        <span>
                            By checking this box you are agreeing to our Terms and Conditions.
                        </span>
                    </div>
                    <div class="flex items-center gap-6">
                        <button class="bg-smicos-primary hover:bg-smicos-primary/80 rounded-full px-6 py-2 text-white">
                            Sign Up
                        </button>
                        <a 
                            href="/academy"
                            class="text-smicos-primary hover:underline"
                        >
                            Login
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <!-- other courses -->
        <div class="flex flex-col bg-gradient-to-r from-[#F2CD59] to-[#E37C0D] p-20 z-[2]">
            <div class="flex items-center justify-between">
                <h1 class="text-[40px] text-white font-extrabold">
                    Other Courses
                </h1>
                <a
                    href="/academy"
                    class="flex gap-2 rounded-full cursor-pointer bg-smicos-secondary hover:bg-smicos-secondary/80 px-8 py-2"
                >
                    <span class="text-[#FFF] max-sm:text-sm font-bold max-sm:p-2 uppercase">
                        View more
                    </span>
                    <ArrowUpRightIcon class="w-5"/>
                </a>
            </div>
            <div class="flex flex-wrap justify-between items-center">
                <div v-for="lesson in lessons" :key="lesson.id">
                    <Card 
                        cardCss="max-w-[25rem]"
                        buttonCss="bg-smicos-secondary hover:bg-smicos-secondary/80 px-10 py-2"
                        buttonText="Play"
                        :item="lesson"
                        :urlRoute="`/academy`"
                    />
                </div>
            </div>
        </div>
    </div>
</template>