<script setup>
import { useStore } from "@/stores/Store";
const store = useStore();
</script>

<template>
    <TransitionGroup
        name="list"
        tag="ul"
        class="fixed space-y-2 font-sans top-[8rem] right-4 z-[70]"
    >
        <li
            v-for="toast in store.toasts"
            :key="toast.id"
            :class="['toast  w-[20rem] rounded-md', toast.level]"
        >
            <div class="relative p-4 pr-12 text-sm">
                <p class="font-semibold">
                    {{ toast.message }}
                </p>

                <button
                    class="absolute top-0 right-0 px-2 text-xl font-semibold rounded-md text-slate-700 hover:text-red-700 hover:bg-red-400/50"
                    @click="store.closeToast(toast.id)"
                >
                    &times; 
                </button>
            </div>

            <!-- loading state -->
            <div
                :class="[
                    'w-full h-1 animate-loading rounded-b-md indicator',
                    toast.level,
                ]"
            ></div>
        </li>
    </TransitionGroup>
</template>

<style scoped>
@keyframes loading {
    0% {
        width: 100%;
    }
    100% {
        width: 0%;
    }
}
.animate-loading {
    animation: loading 3s infinite ease-in-out;
}

.toast {
    --success-bg: rgba(240, 253, 244, 0.8);
    --success-text: #31784b;

    --warning-bg: rgba(254, 252, 232, 0.8);
    --warning-text: #a96e19;

    --danger-bg: rgba(254, 242, 242, 0.8);
    --danger-text: #c33e3e;

    box-shadow: 0 4px 30px rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}
.success {
    background-color: var(--success-bg);
    color: var(--success-text);
}

.warning {
    background-color: var(--warning-bg);
    color: var(--warning-text);
}

.danger {
    background-color: var(--danger-bg);
    color: var(--danger-text);
}

.indicator.success {
    background-color: var(--success-text);
}

.indicator.warning {
    background-color: var(--warning-text);
}

.indicator.danger {
    background-color: var(--danger-text);
}
</style>
