<script setup>
const faqs = [
    {
        id: 1,
        question: "What is Smicos all about?",
        answer: "Smicos (Smart Integrated Communication Solutions) is an all-in-one platform AI powered customer management and marketing chatbot solutions for SMALL BUSINESSES to ENTERPRISE. It harnesses the power of artificial intelligence and natural language processing (NLP) to understand and respond to customer queries and requests, simulating human conversation."
    },
    {
        id: 2,
        question: "Who is behind the creation of Smicos?",
        answer: "Smicos was developed by PurpleBug, a company dedicated to providing innovative digital solutions. PurpleBug is a top digital marketing agency offering complete solutions for brands, specializing in web and mobile development. With over a decade of experience, they provide high-quality and cost-effective services, including web design, SEO, online advertising, social media management, PR media, and app development."
    },
    {
        id: 3,
        question: "How much does Smicos cost?",
        answer: "To get detailed information on pricing, please visit our pricing page."
    },
    {
        id: 4,
        question: "What solutions does Smicos offer?",
        answer: "Smicos offers a range of solutions including increased lead engagement and conversion, enhanced customer management, and comprehensive campaign performance tracking."
    },
    {
        id: 5,
        question: "How can I get in touch with Smicos?",
        answer: "You can reach out to Smicos through our contact page or by emailing us directly at inquiries@smicos.com. We’re here to assist you with any questions or support you may need."
    },
    {
        id: 6,
        question: "What features does Smicos include?",
        answer: "Smicos boasts a variety of features such as the Smicos Analytics and Report Management, Customer Database, Story Training, Ticketing Tool, Live Agent Feature, Easy Story Maker, Easy Story Templates, and Easy Integrations. For more info, check out our solutions page here."
    },
    {
        id: 7,
        question: "How do I submit an inquiry to Smicos?",
        answer: "You can send an inquiry by filling out the form on our contact page or book a free onboarding session via Smicos Connect. Our team will get back to you promptly."
    }
];
</script>

<template>
    <div class="flex flex-col">
        <!-- FAQs Banner -->
        <div 
            :style="`background: url('images/homepage/solutions-banner-bg.png') no-repeat; background-size: cover; background-position: center;`" 
            class="relative flex flex-col items-center justify-center md:gap-6 gap-2 w-full 2xl:h-[800px] lg:h-[750px] h-[400px]"
        >   
            <span class="md:text-[30px] text-[24px] font-bold">Do you have any questions?</span>
            <span class="md:text-[65px] text-[28px] font-extrabold bg-gradient-to-r from-[#E1251B] to-[#E37C0D] bg-clip-text max-sm:text-center text-transparent">
                Frequently Ask Questions (FAQs)
            </span>
            <span class="md:text-[24px] text-[18px] max-sm:text-center">Find answers to the most commonly asked questions below</span>
        </div>
        <div class="flex justify-center w-full h-full py-10 bg-gradient-to-r from-[#31549D] to-[#0A9EDF]">
            <div class="flex flex-col gap-6 max-w-[80%] w-full">
                <div
                    v-for="faq in faqs"
                    class="flex flex-col gap-4"
                >
                    <div class="flex gap-2 leading-tight font-extrabold md:text-[40px] text-[34px] bg-gradient-to-r from-[#E37C0D] to-[#F2CD59] bg-clip-text text-transparent">
                        <span>
                            {{ `${faq.id}.` }}
                        </span>
                        <span>
                            {{ faq.question }}
                        </span>
                    </div>
                    <span class="text-[18px] text-[#FFF]">
                        {{ faq.answer }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>