export const solutionsArray = () => {
    const sections = [
        {
            id: 1, 
            name: "Total Number of Customers", 
            description: "Have a quick overview of the total number of customers interacting with your Smicos bot—essential for understanding engagement and optimizing your support strategy.",
            section: "dashboard"
        },
        {
            id: 2, 
            name: "Bot Messages Count", 
            description: "Track the total number of Smicos bot messages sent to customers in each conversation — a key metric for understanding engagement and optimizing customer interactions.",
            section: "dashboard"
        },
        {
            id: 3, 
            name: "Average Conversation Length", 
            description: "Measures the duration of each conversation with the bot or agent over a set period, offering insights to optimize response times, improve efficiency, and enhance the overall customer experience.",
            section: "dashboard"
        },
        {
            id: 4, 
            name: "Chat to Live Agent Statistics", 
            description: "Tracks the volume of customers opting to connect with a human agent for resolving inquiries, offering valuable insights into when personal assistance is essential for customer satisfaction.",
            section: "dashboard"
        },
        {
            id: 5, 
            name: "Busiest Time Period", 
            description: "Identifies the peak times for bot or agent interactions, helping you allocate resources effectively to manage high-demand periods.",
            section: "dashboard"
        },
        {
            id: 6, 
            name: "Recent Tickets Overview", 
            description: "Provides a real-time snapshot of recent customer support tickets, ensuring you're always on top of inquiries and ready to deliver timely solutions.",
            section: "dashboard"
        },
        {
            id: 7, 
            name: "Word Cloud", 
            description: "A visual snapshot of customer messages, where word size reflects frequency and importance, helping you quickly identify key topics and emerging trends raised to address.",
            section: "dashboard"
        },
        {
            id: 8, 
            name: "Top & Under Performance Overview", 
            description: "Top Performance Overview – Displays high-engagement story flows for targeted success; Under Performance Overview – Reveals low-engagement flows for focused improvement.",
            section: "dashboard"
        },
        {
            id: 9, 
            name: "Frequently Unrecognized Questions", 
            description: "Highlights common customer queries that your chatbot misses, guiding improvements to enhance response accuracy.",
            section: "dashboard"
        },
        {
            id: 10, 
            name: "Free Templates", 
            description: "Access a variety of customizable templates at no cost, streamlining your story creation process and empowering your business to save time and resources while creating impactful stories efficiently.",
            section: "story-maker"
        },
        {
            id: 11, 
            name: "Easy Integration", 
            description: "Seamlessly connect with your existing systems for a smooth and efficient setup, reducing implementation hassles.",
            section: "story-maker"
        },
        {
            id: 12, 
            name: "Customized Experience", 
            description: "Tailor your story elements to fit your specific needs and preferences. Add your logo, adjust to your branding, and more.",
            section: "story-maker"
        },
        {
            id: 13, 
            name: "Train Bot Response", 
            description: "Enhance your chatbot’s accuracy by refining its responses based on real interactions, leading to improved customer satisfaction.",
            section: "story-maker"
        },
        {
            id: 14, 
            name: "Stories Overview for All Integrations", 
            description: "Get a comprehensive view of your stories across all connected platforms, simplifying management and ensuring consistency.",
            section: "story-maker"
        },
        {
            id: 15, 
            name: "Usage Information", 
            description: "Track and analyze usage data to optimize performance and ensure effective engagement, helping you make informed decisions.",
            section: "story-maker"
        },
        {
            id: 16, 
            name: "Customer Tags", 
            description: "Group and categorize your database effectively, facilitating targeted marketing and streamlined management for enhanced engagement and efficiency.",
            section: "customer-database"
        },
        {
            id: 17, 
            name: "Enrich User Profiles", 
            description: "Track the total number of Smicos bot messages sent to customers in each conversation — a key metric for understanding engagement and optimizing customer interactions.",
            section: "customer-database"
        },
        {
            id: 18, 
            name: "Easy Filtering", 
            description: "Quickly sift through customer data to find specific information, streamlining data management, boosting operational efficiency, and ensuring prompt, accurate responses.",
            section: "customer-database"
        },
        {
            id: 19, 
            name: "Kanban Dashboard", 
            description: "Visualize and manage ticket workflows effortlessly, ensuring smooth operations and timely issue resolution.",
            section: "ticketing"
        },
        {
            id: 20, 
            name: "Ticket Information Overview", 
            description: "Access a comprehensive summary of ticket details, enhancing oversight and streamlining support management.",
            section: "ticketing"
        },
        {
            id: 21, 
            name: "Easy Notes", 
            description: "Quickly add notes on tickets, improving record-keeping and ensuring crucial information is readily available for effective support.",
            section: "ticketing"
        },
        {
            id: 22, 
            name: "Seamless Live Agent Transfer", 
            description: "Effortlessly transition conversations between agents, ensuring continuous support and a smooth customer experience, reducing wait times and avoiding information loss.",
            section: "live-agent"
        },
        {
            id: 23, 
            name: "Quick Response Options", 
            description: "Provide rapid replies with pre-defined responses, accelerating issue resolution and enhancing customer satisfaction, while maintaining consistency and efficiency.",
            section: "live-agent"
        },
        {
            id: 24, 
            name: "Easy Ticket Creation", 
            description: "Streamline the process of creating support tickets, enhancing efficiency and ensuring that no customer request goes unaddressed, leading to faster resolution and improved service quality.",
            section: "live-agent"
        }
    ]

    const banners = [
        { 
            id: 1, 
            file: "solutions-banner-bg.png", 
            title: "Empower Your Business with AI-Powered Customer Support.", 
            titleStyle: "text-[#1F1F1F]", 
            description: "Simplify It!", 
            descriptionStyle: "bg-gradient-to-r from-[#E1251B] to-[#E37C0D] bg-clip-text text-transparent", 
            actionText: "A game-changing platform designed for businesses of all sizes. This all-in-one solution leverages AI to help businesses increase sales and conversions, capture leads, and better understand their customers, elevating customer service experiences across industries.",
            actionTextStyle: "text-[#8F8F8F]",
            actionImage: null, 
            buttonImage: "banner-btn4.png",
            position: "justify-center"
        },
        { 
            id: 2, 
            file: "homepage-banner-1.png", 
            title: "Increase sales with good customer service.", 
            titleStyle: "text-[#FFF]", 
            description: "Brew better customer relationships with a Chatbot!", 
            descriptionStyle: "text-[#FFF]",
            actionText: null,
            actionTextStyle: null,
            actionImage: "cta-1.png", 
            buttonImage: "banner-btn4.png",
            position: "justify-left xl:px-20"
        },
        { 
            id: 3, 
            file: "homepage-banner-2.png", 
            title: "Drive more sales with good customer management.", 
            titleStyle: "text-[#FFF]", 
            description: "That's why all business need better Chatbot!", 
            descriptionStyle: "text-[#FFF]",
            actionText: null,
            actionTextStyle: null,
            actionImage: "cta-1.png", 
            buttonImage: "banner-btn4.png",
            position: "justify-left xl:px-20"
        },
        { 
            id: 4, 
            file: "homepage-banner-3.png", 
            title: "Build higher sales with good customer management.", 
            titleStyle: "text-[#FFF]", 
            description: "Learn how an AI chatbot can be the foundation of an effective business.", 
            descriptionStyle: "text-[#FFF]",
            actionText: null,
            actionTextStyle: null,
            actionImage: "cta-1.png", 
            buttonImage: "banner-btn4.png",
            position: "justify-left xl:px-20"
        },
    ]

    return {
        sections,
        banners
    };
};
