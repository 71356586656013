<script setup>
import { reactive, ref } from 'vue';
import { useStore } from "@/stores/Store";
import { useAuthStore } from "@/stores/Auth";
import { inputOptions } from "@/data/registration.js";

import Toast from "@components/includes/Toast.vue";

const props = defineProps({slug: String});

const store = useStore();
const storeAuth = useAuthStore();
const inputs = inputOptions();

const options = reactive({
    headers: {
        Authorization: ""
    }
});

const errors = ref({});

const formData = reactive({
    website_training_id: "",
    webinar_training_title: "",
    user_id: "",
    email: "",
    age: "",
    first_name: "",
    last_name: "",
    location: "",
    career: "",
    industry: "",
    is_first_time: "",
    finding: "",
    ratings: {
        speaker_one: [],
        speaker_two: [],
        webinar: []
    },
    question: "",
    suggestions: ""
});

const submitSurvey = async () => {
    try {
        formData.ratings = JSON.stringify(formData.ratings);
        await window.axios.post('https://smicos.com/api/website/sanctum/survey-form', formData, options);
        store.flash("Survey submitted, Thank you for your participation.");
    } catch (error) {
        console.error(error);
    }
}

const satisfactionRating = (category, criteriaName, satisfactionValue) => {
    const hasValue = formData.ratings[category].some((rating) => rating.criteria === criteriaName);
    if(!hasValue) {
        return formData.ratings[category].push({
            criteria: criteriaName,
            rate: satisfactionValue
        });
    }

    formData.ratings[category].map((rating) => {
        if(rating.criteria === criteriaName) {
            rating.rate = satisfactionValue;
        }
    });
}

const academy = ref({});

(async () => {
    const res = await axios.get(`https://app.smicos.com/api/website/sanctum/website-trainings`);
    academy.value = res.data.find((a) => a.slug === props.slug);

    storeAuth.auth = JSON.parse(localStorage.getItem('user')) ?? {};
    
    if (storeAuth.auth.id) {
        options.headers.Authorization = `Bearer ${storeAuth.auth.api_token}`;

        const res = await axios('https://app.smicos.com/api/website/sanctum/user', options); 
        Object.assign(storeAuth.auth, res.data);
        localStorage.setItem('user', JSON.stringify(storeAuth.auth));

        // Set formData 
        formData.email = storeAuth.auth.email;
        formData.user_id = storeAuth.auth.id;
        formData.website_training_id = academy.id;
        formData.website_training_titles = academy.title;
    }else{
        window.location.href = `/academy/${props.slug}`;
    }
})();
</script>

<template>
    <div class="flex flex-col gap-10 items-center pt-[7.5rem] border-b">
        <img 
            :src="academy.banner" 
            alt="training banner" 
            class=" w-full max-w-screen-lg"
        >
        <h1 class="font-extrabold md:text-[40px] text-[34px] bg-gradient-to-r from-[#E37C0D] to-[#F2CD59] bg-clip-text text-transparent">
            {{ academy.title }}
        </h1>
        <div class="flex flex-col gap-6 pb-10 w-full max-w-screen-lg">
            <div class="flex items-center gap-6 w-full">
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-sm">
                        Email
                    </span>
                    <input 
                        v-model="formData.email"
                        type="text"
                        class="border w-full rounded-md p-2 focus:outline-smicos-primary disabled:bg-slate-200 disabled:cursor-not-allowed"
                        placeholder="Email address"
                        disabled
                    >
                    <!-- <span
                        v-if="errors.email" 
                        class="absolute top-[100%] text-smicos-secondary text-sm font-bold"
                    >
                        {{ errors.email[0] }}
                    </span> -->
                </div>
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-sm">
                        Age
                    </span>
                    <div class="border rounded-md p-2 cursor-pointer w-full">
                        <select 
                            @change="($event) => formData.age = $event.target.value"
                            class="appearance-auto w-full appearance-none outline-none cursor-pointer"
                        >
                            <option 
                                v-for="age, index in inputs.ages" 
                                :value="age"
                                :key="index"
                                class="bg-white"
                            >
                                {{ age }}
                            </option>
                        </select>
                    </div>
                    <!-- <span
                        v-if="errors.email" 
                        class="absolute top-[100%] text-smicos-secondary text-sm font-bold"
                    >
                        {{ errors.email[0] }}
                    </span> -->
                </div>
            </div>
            <div class="flex items-center gap-6 w-full">
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-sm">
                        First name
                    </span>
                    <input 
                        v-model="formData.first_name"
                        type="text"
                        class="border w-full rounded-md p-2 focus:outline-smicos-primary"
                        placeholder="First name"
                    >
                    <!-- <span
                        v-if="errors.email" 
                        class="absolute top-[100%] text-smicos-secondary text-sm font-bold"
                    >
                        {{ errors.email[0] }}
                    </span> -->
                </div>
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-sm">
                        Last name
                    </span>
                    <input 
                        v-model="formData.last_name"
                        type="text"
                        class="border w-full rounded-md p-2 focus:outline-smicos-primary"
                        placeholder="Last name"
                    >
                    <!-- <span
                        v-if="errors.email" 
                        class="absolute top-[100%] text-smicos-secondary text-sm font-bold"
                    >
                        {{ errors.email[0] }}
                    </span> -->
                </div>
            </div>
            <div class="flex items-center gap-6 w-full">
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-sm">
                        Location
                    </span>
                    <div class="border rounded-md p-2 cursor-pointer w-full">
                        <select 
                            @change="($event) => formData.location = $event.target.value"
                            class="appearance-auto w-full appearance-none outline-none cursor-pointer"
                        >
                            <option 
                                v-for="location, index in inputs.locations" 
                                :value="location"
                                :key="index"
                                class="bg-white"
                            >
                                {{ location }}
                            </option>
                        </select>
                    </div>
                    <!-- <span
                        v-if="errors.email" 
                        class="absolute top-[100%] text-smicos-secondary text-sm font-bold"
                    >
                        {{ errors.email[0] }}
                    </span> -->
                </div>
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-sm">
                        Select the option that best describes you
                    </span>
                    <div class="border rounded-md p-2 cursor-pointer w-full">
                        <select 
                            @change="($event) => formData.career = $event.target.value"
                            class="appearance-auto w-full appearance-none outline-none cursor-pointer"
                        >
                            <option 
                                v-for="career, index in inputs.careers" 
                                :value="career"
                                :key="index"
                                class="bg-white"
                            >
                                {{ career }}
                            </option>
                        </select>
                    </div>
                    <!-- <span
                        v-if="errors.email" 
                        class="absolute top-[100%] text-smicos-secondary text-sm font-bold"
                    >
                        {{ errors.email[0] }}
                    </span> -->
                </div>
            </div>
            <div class="flex items-center gap-6 w-full">
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-sm">
                        Nature of business
                    </span>
                    <div class="border rounded-md p-2 cursor-pointer w-full">
                        <select 
                            @change="($event) => formData.industry = $event.target.value"
                            class="appearance-auto w-full appearance-none outline-none cursor-pointer"
                        >
                            <option 
                                v-for="industry, index in inputs.industries" 
                                :value="industry"
                                :key="index"
                                class="bg-white"
                            >
                                {{ industry }}
                            </option>
                        </select>
                    </div>
                    <!-- <span
                        v-if="errors.email" 
                        class="absolute top-[100%] text-smicos-secondary text-sm font-bold"
                    >
                        {{ errors.email[0] }}
                    </span> -->
                </div>
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-sm">
                        Is it your first time attending a webinar hosted by Smicos?
                    </span>
                    <div class="border rounded-md p-2 cursor-pointer w-full">
                        <select 
                            @change="($event) => formData.is_first_time = $event.target.value"
                            class="appearance-auto w-full appearance-none outline-none cursor-pointer"
                        >
                            <option 
                                class="bg-white"
                                value="Yes"
                            >
                                Yes
                            </option>
                            <option 
                                class="bg-white"
                                value="No"
                            >
                                No
                            </option>
                        </select>
                    </div>
                    <!-- <span
                        v-if="errors.email" 
                        class="absolute top-[100%] text-smicos-secondary text-sm font-bold"
                    >
                        {{ errors.email[0] }}
                    </span> -->
                </div>
            </div>

            <!-- Speakers satisfaction -->
            <div class="flex flex-col gap-10 py-4">
                <div class="flex flex-col gap-6">
                    <div class="flex flex-col">
                        <span class="font-bold">
                            SPEAKER ONE:
                        </span>
                        <span class="text-sm">
                            How would you rate the overall presentation given by <b>Patti Germentil</b> on the topic of <b>Changing the Digital Marketing Landscape with AI</b> based on the criteria listed below?
                        </span>
                    </div>
                    <!-- header -->
                    <div class="flex items-center justify-center text-center gap-4">
                        <div class="w-full"></div>
                        <div
                            v-for="satisfaction in inputs.satisfactions"
                            class="w-full font-bold text-sm"
                        >
                            {{ satisfaction }}
                        </div>
                    </div>
                    <!-- body -->
                    <div
                        v-for="item in inputs.criteria"
                        class="flex items-center gap-4"
                    >
                        <div class="w-full text-left">
                            {{ item }}
                        </div>
                        <div
                            v-for="satisfaction, index in inputs.satisfactions"
                            :key="index"
                            class="w-full text-center"
                        >
                            <input
                                @change="satisfactionRating('speaker_one', item, satisfaction)"
                                type="radio"
                                :name="`speaker1-${item}`"
                                class="w-6 h-6 appearance-none border-2 border-solid border-gray-400 rounded-full checked:bg-blue-600 checked:border-transparent focus:outline-none cursor-pointer"
                            >
                        </div>
                    </div>
                </div>
                <div class="flex flex-col gap-6">
                    <div class="flex flex-col">
                        <span class="font-bold">
                            SPEAKER TWO:
                        </span>
                        <span class="text-sm">
                            How would you rate the overall presentation given by <b>Kayla Ambayec</b> on the topic of <b>Changing the Digital Marketing Landscape with AI</b> based on the criteria listed below?
                        </span>
                    </div>
                    <!-- header -->
                    <div class="flex items-center justify-center gap-4 text-center">
                        <div class="w-full"></div>
                        <div
                            v-for="satisfaction in inputs.satisfactions"
                            class="w-full font-semibold"
                        >
                            {{ satisfaction }}
                        </div>
                    </div>
                    <!-- body -->
                    <div
                        v-for="item in inputs.criteria"
                        class="flex items-center justify-center gap-4 text-center"
                    >
                        <div class="w-full text-left">
                            {{ item }}
                        </div>
                        <div
                            v-for="satisfaction, index in inputs.satisfactions"
                            :key="index"
                            class="w-full"
                        >
                            <input
                                @change="satisfactionRating('speaker_two', item, satisfaction)"
                                type="radio"
                                :name="`speaker2-${item}`"
                                class="w-6 h-6 appearance-none border-2 border-solid border-gray-400 rounded-full checked:bg-blue-600 checked:border-transparent focus:outline-none cursor-pointer"
                            >
                        </div>
                    </div>
                </div>
                <div class="flex flex-col gap-6">
                    <div class="flex flex-col">
                        <span class="font-bold">
                            WEBINAR EXPERIENCE:
                        </span>
                    </div>
                    <!-- header -->
                    <div class="flex items-center justify-center gap-4 text-center">
                        <div class="w-full"></div>
                        <div
                            v-for="satisfaction in inputs.satisfactions"
                            class="w-full font-semibold"
                        >
                            {{ satisfaction }}
                        </div>
                    </div>
                    <!-- body -->
                    <div
                        v-for="item in inputs.experiences"
                        class="flex items-center justify-center gap-4 text-center"
                    >
                        <div class="w-full text-left">
                            {{ item }}
                        </div>
                        <div
                            v-for="satisfaction, index in inputs.satisfactions"
                            :key="index"
                            class="w-full"
                        >
                            <input
                                @change="satisfactionRating('webinar', item, satisfaction)"
                                type="radio"
                                :name="`experience-${item}`"
                                class="w-6 h-6 appearance-none border-2 border-solid border-gray-400 rounded-full checked:bg-blue-600 checked:border-transparent focus:outline-none cursor-pointer"
                            >
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="flex items-center gap-6 w-full">
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-sm">
                        How did you hear about Smicos?
                    </span>
                    <div class="border rounded-md p-2 cursor-pointer w-full">
                        <select 
                            @change="($event) => formData.finding = $event.target.value"
                            class="appearance-auto w-full appearance-none outline-none cursor-pointer"
                        >
                            <option 
                                v-for="finding, index in inputs.findings" 
                                :value="finding"
                                :key="index"
                                class="bg-white"
                            >
                                {{ finding }}
                            </option>
                        </select>
                    </div>
                    <!-- <span
                        v-if="errors.email" 
                        class="absolute top-[100%] text-smicos-secondary text-sm font-bold"
                    >
                        {{ errors.email[0] }}
                    </span> -->
                </div>
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-sm">
                        Additional question
                    </span>
                    <input 
                        v-model="formData.question"
                        type="text"
                        class="border w-full rounded-md p-2 focus:outline-smicos-primary"
                        placeholder="Do you have any additional questions for any of the speakers? You may send them here."
                    >
                    <!-- <span
                        v-if="errors.email" 
                        class="absolute top-[100%] text-smicos-secondary text-sm font-bold"
                    >
                        {{ errors.email[0] }}
                    </span> -->
                </div>
            </div>
            <div class="relative flex flex-col gap-2 w-full">
                <span class="font-semibold text-sm">
                    Are there any topics you would like to see for our future webinars?
                </span>
                <textarea 
                    v-model="formData.suggestions"
                    rows="10"
                    class="border rounded-md resize-none p-2 focus:outline-smicos-primary"
                    placeholder="Are there any topics you would like to see for our future webinars?"
                >
                </textarea>
                <!-- <span
                    v-if="errors.email" 
                    class="absolute top-[100%] text-smicos-secondary text-sm font-bold"
                >
                    {{ errors.email[0] }}
                </span> -->
            </div>

            <div class="flex items-center justify-center">
                <button 
                    @click="submitSurvey"
                    class="bg-smicos-primary hover:opacity-70 p-4 rounded-lg text-white font-semibold w-[20rem]"
                >
                    Submit
                </button>
            </div>
        </div>
        <Toast />
    </div>
</template>