<script setup>
// import { useStore } from "@/Stores/Store";

// const store = useStore();

const props = defineProps({
    title: String,
    bodyCss: {
        default: "max-w-2xl",
    },
    titleCss: {
        default: "text-left",
    },
    isConfirmDisabled: {
        type: Boolean,
        default: false,
    },
    confirmLabel: {
        default: "Confirm",
    },
    confirmLabelCss: {
        default: 'text-white button bg-smicos-primary/90  hover:bg-smicos-primary',
    },
    cancelLabel: {
        default: "Cancel",
    },
    isForm: {
        type: Boolean,
        default: false,
    },
    persist: {
        default: false,
    },
    hasConfirm: {
        type: Boolean,
        default: true,
    },
});
const emits = defineEmits(["close", "confirm"]);

// const clickAway = () => {
//     if (props.persist)
//         return store.flash("Please enter information", "warning");

//     emits("close");
// };
</script>

<template>
    <div>
        <Teleport to="#outside">
            <Transition>
                <div
                    @click.self="emits('close')"
                    tabindex="-1"
                    aria-hidden="true"
                    class="fixed top-0 left-0 right-0 flex items-center justify-center w-full h-screen max-h-full p-4 overflow-x-hidden overflow-y-auto z-[99999] bg-black/50 md:inset-0"
                >
                    <component
                        :is="isForm ? 'form' : 'div'"
                        @submit.prevent="emits('confirm')"
                        :class="[
                            'font-sans relative w-full max-h-full',
                            bodyCss,
                        ]"
                    >
                        <!-- Modal content -->
                        <div class="relative bg-white rounded-lg shadow">
                            <!-- Modal header -->
                            <div
                                class="flex items-start justify-between p-4 border-b rounded-t font-gotham"
                            >
                                <h3
                                    :class="[
                                        'text-xl font-semibold text-gray-900',
                                        titleCss,
                                    ]"
                                >
                                    {{ title }}
                                </h3>
                                <button
                                    @click="emits('close')"
                                    type="button"
                                    class="inline-flex items-center justify-center w-8 h-8 ml-auto text-sm bg-transparent rounded-lg hover:bg-red-200 hover:text-red-900"
                                >
                                    <svg
                                        class="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                    <span class="sr-only">Close modal</span>
                                </button>
                            </div>
                            <!-- Modal body -->
                            <div
                                class="overflow-y-auto max-h-[80vh] custom-scrollbar"
                            >
                                <slot />
                            </div>
                            <!-- Modal footer -->
                            <div class="">
                                <slot name="footer">
                                    <div
                                        class="flex items-center justify-end p-6 space-x-2 border-t border-gray-200 rounded-b"
                                    >
                                        <button
                                            @click="emits('close')"
                                            type="button"
                                            class="border opacity-80 button border-slate-400 hover:opacity-100"
                                        >
                                            {{ cancelLabel }}
                                        </button>
                                        <button
                                            v-if="hasConfirm"
                                            :disabled="isConfirmDisabled"
                                            @click="!isForm && emits('confirm')"
                                            :type="isForm ? 'submit' : 'button'"
                                            :class="[
                                                confirmLabelCss,
                                                isConfirmDisabled &&
                                                    'cursor-not-allowed opacity-75',
                                            ]"
                                        >
                                            {{ confirmLabel }}
                                        </button>
                                    </div>
                                </slot>
                            </div>
                        </div>
                    </component>
                </div>
            </Transition>
        </Teleport>
    </div>
</template>
