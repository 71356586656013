<script setup>
import { reactive, ref } from "vue";

const FormData = reactive({
    first_name: "",
    last_name: "",
    contact_number: "",
    company_name: "",
    position: "",
    nature_of_business: "",
    size_of_company: "",
    purpose: "",
    business_email: "",
    checkbox: "",
})
const done = ref(true);
const errors = ref([]);
const registerForMeeting = async () => {
    await axios.post('/register-for-meeting', FormData)
    .then(response => {
        done.value = false;
        location.href = "/ai-revolution-transforming-customer-management-in-philippine-businesses-webinar/thank-you";
    })
    .catch(error => {
        errors.value = error.response.data.errors;
        setTimeout(() => {
            errors.value = [];
        }, 5000);
    });
};

</script>
<template>
    <div class="flex items-center flex-col xl:pt-[7rem]">
        <div>
            <img :src="'/images/registration-banner.webp'" alt="webinar banner" class=" w-full max-w-[1566px]">
        </div>
        <div v-if="done" class="flex-col items-center w-full max-w-[1566px] bg-gradient-to-b from-[#2863AA] to-[#03A8E8] flex justify-center p-10">
            <h1 class="text-[20px] lg:text-[35px] text-white font-bold max-w-[700px] text-center">Secure your exclusive access to the webinar by registering now.</h1>
            <br>
            <div class="border-white border-2 rounded-md bg-white w-full max-w-[800px]"></div>
            <div class="flex justify-center w-full max-w-[800px] py-5 gap-7 flex-wrap">
                <div class="flex flex-col w-full md:w-[380px]">
                    <label class="font-bold text-white" for="first_name">First Name</label>
                    <input placeholder="Enter First Name" required v-model="FormData.first_name" type="text" class="text-[gray] focus:text-[black] focus:border-[#00AEEE] focus:ring-[#00AEEE] rounded-lg shadow-sm darke:bg-gray-700 darke:border-gray-600 darke:text-white border-[#00AEEE] bg-[#F6F6F6]/80 placeholder:text-[#1E1E1E]/60 px-4 py-2">
                    <span class="text-red-500 text-xs bg-[#ffffff] font-bold rounded-md ease-in-out text-left max-w-[250px] mt-1 px-3">
                         {{ errors.first_name ? errors.first_name[0] : '' }}
                    </span>
                </div>
                <div class="flex flex-col w-full md:w-[380px]">
                    <label class="font-bold text-white" for="last_name">Last Name</label>
                    <input placeholder="Enter Last Name" required v-model="FormData.last_name" type="text" class="text-[gray] focus:text-[black] focus:border-[#00AEEE] focus:ring-[#00AEEE] rounded-lg shadow-sm darke:bg-gray-700 darke:border-gray-600 darke:text-white border-[#00AEEE] bg-[#F6F6F6]/80 placeholder:text-[#1E1E1E]/60 px-4 py-2">
                    <span class="text-red-500 text-xs bg-[#ffffff] font-bold rounded-md ease-in-out text-left max-w-[250px] mt-1 px-3">
                         {{ errors.last_name ? errors.last_name[0] : '' }}
                    </span>
                </div>
                <div class="flex flex-col w-full md:w-[380px]">
                    <label class="font-bold text-white" for="last_name">Contact Number</label>
                    <input  placeholder="0000 0000 0000" required v-model="FormData.contact_number" type="text" class="text-[gray] focus:text-[black] focus:border-[#00AEEE] focus:ring-[#00AEEE] rounded-lg shadow-sm darke:bg-gray-700 darke:border-gray-600 darke:text-white border-[#00AEEE] bg-[#F6F6F6]/80 placeholder:text-[#1E1E1E]/60 px-4 py-2">
                    <span class="text-red-500 text-xs bg-[#ffffff] font-bold rounded-md ease-in-out text-left max-w-[250px] mt-1 px-3">
                         {{ errors.contact_number ? errors.contact_number[0] : '' }}
                    </span>
                </div>
                <div class="flex flex-col w-full md:w-[380px]">
                    <label class="font-bold text-white" for="last_name">Company Name</label>
                    <input placeholder="Enter Company Name" required v-model="FormData.company_name" type="text" class="text-[gray] focus:text-[black] focus:border-[#00AEEE] focus:ring-[#00AEEE] rounded-lg shadow-sm darke:bg-gray-700 darke:border-gray-600 darke:text-white border-[#00AEEE] bg-[#F6F6F6]/80 placeholder:text-[#1E1E1E]/60 px-4 py-2">
                    <span class="text-red-500 text-xs bg-[#ffffff] font-bold rounded-md ease-in-out text-left max-w-[250px] mt-1 px-3">
                         {{ errors.company_name ? errors.company_name[0] : '' }}
                    </span>
                </div>
                <div class="flex flex-col w-full md:w-[380px]">
                    <label class="font-bold text-white" for="last_name">Position</label>
                    <input placeholder="Enter Position" required v-model="FormData.position" type="text" class="text-[gray] focus:text-[black] focus:border-[#00AEEE] focus:ring-[#00AEEE] rounded-lg shadow-sm darke:bg-gray-700 darke:border-gray-600 darke:text-white border-[#00AEEE] bg-[#F6F6F6]/80 placeholder:text-[#1E1E1E]/60 px-4 py-2">
                    <span class="text-red-500 text-xs bg-[#ffffff] font-bold rounded-md ease-in-out text-left max-w-[250px] mt-1 px-3">
                         {{ errors.position ? errors.position[0] : '' }}
                    </span>
                </div>
                <div class="flex flex-col w-full md:w-[380px]">
                    <label class="font-bold text-white" for="last_name">Nature of Business</label>
                    <select required v-model="FormData.nature_of_business" type="text" class="text-[gray] focus:text-[black] focus:border-[#00AEEE] focus:ring-[#00AEEE] rounded-lg shadow-sm darke:bg-gray-700 darke:border-gray-600 darke:text-white border-[#00AEEE] bg-[#F6F6F6]/80 px-4 py-2" >
                        <option value="" selected disabled>Select Business</option>
                        <option value="Agriculture">Agriculture</option>
                        <option value="Apparel & Fashion">Apparel & Fashion</option>
                        <option value="Automotive">Automotive</option>
                        <option value="Banking">Banking</option>
                        <option value="Biotechnology">Biotechnology</option>
                        <option value="Chemicals">Chemicals</option>
                        <option value="Construction">Construction</option>
                        <option value="Consulting">Consulting</option>
                        <option value="Education">Education</option>
                        <option value="Electronics">Electronics</option>
                        <option value="Energy">Energy</option>
                        <option value="Energy">Energy</option>
                        <option value="Entertainment">Entertainment</option>
                        <option value="Environmental">Environmental</option>
                        <option value="Finance">Finance</option>
                        <option value="Food & Beverage">Food & Beverage</option>
                        <option value="Government">Government</option>
                        <option value="Healthcare">Healthcare</option>
                        <option value="Hospitality">Hospitality</option>
                        <option value="Insurance">Insurance</option>
                        <option value="Manufacturing">Manufacturing</option>
                        <option value="Marketing">Marketing</option>
                        <option value="Media">Media</option>
                        <option value="Non-profit">Non-profit</option>
                        <option value="Pharmaceuticals">Pharmaceuticals</option>
                        <option value="Real Estate">Real Estate</option>
                        <option value="Retail">Retail</option>
                        <option value="Software">Software</option>
                        <option value="Technology">Technology</option>
                        <option value="Telecommunications">Telecommunications</option>
                        <option value="Transportation">Transportation</option>
                        <option value="Utilities">Utilities</option>
                        <option value="Others">Others</option>
                    </select>
                    <span class="text-red-500 text-xs bg-[#ffffff] font-bold rounded-md ease-in-out text-left max-w-[300px] mt-1 px-3">
                         {{ errors.nature_of_business ? errors.nature_of_business[0] : '' }}
                    </span>
                </div>
                <div class="flex flex-col w-full md:w-[380px]">
                    <label class="font-bold text-white" for="last_name">Size of the Company</label>
                    <select required v-model="FormData.size_of_company" type="text" class="text-[gray] focus:text-[black] focus:border-[#00AEEE] focus:ring -[#00AEEE] rounded-lg shadow-sm darke:bg-gray-700 darke:border-gray-600 darke:text-white border-[#00AEEE] bg-[#F6F6F6]/80 placeholder:text-[gray]/60 px-4 py-2" >
                        <option value="" disabled selected>Select Size</option>
                        <option value="1-10 Employees">1-10 Employees</option>
                        <option value="11-50 Employees">11-50 Employees</option>
                        <option value="51-200 Employees">51-200 Employees</option>
                        <option value="201-500 Employees">201-500 Employees</option>
                        <option value="501-1000 Employees">501-1000 Employees</option>
                        <option value="1001-5000 Employees">1001-5000 Employees</option>
                        <option value="5001 - 10,000 Employees">5001 - 10,000 Employees</option>
                        <option value="10,001+ Employees">10,001+ Employees</option>
                    </select>
                    <span class="text-red-500 text-xs bg-[#ffffff] font-bold rounded-md ease-in-out text-left max-w-[300px] mt-1 px-3">
                         {{ errors.size_of_company ? errors.size_of_company[0] : '' }}
                    </span>
                </div>
                <div class="flex flex-col w-full md:w-[380px]">
                    <label class="font-bold text-white" for="last_name">Purpose</label>
                    <select required v-model="FormData.purpose" type="text" class="text-[gray] focus:text-[black] focus:border-[#00AEEE] focus:ring-[#00AEEE] rounded-lg shadow-sm darke:bg-gray-700 darke:border-gray-600 darke:text-white border-[#00AEEE] bg-[#F6F6F6]/80 placeholder:text-[gray]/60 px-4 py-2">
                        <option value="" disabled selected>Select Purpose</option>
                        <option value="Understand AI in customer management">Understand AI in customer management</option>
                        <option value="Learn latest AI trends">Learn latest AI trends</option>
                        <option value="Explore AI applications for business">Explore AI applications for business</option>
                        <option value="Network with professionals">Network with professionals</option>
                        <option value="Enhance AI skills and knowledge">Enhance AI skills and knowledge</option>
                        <option value="Solve customer management challenges">Solve customer management challenges</option>
                        <option value="Stay updated on AI in the Philippines">Stay updated on AI in the Philippines</option>
                        <option value="Others">Others</option>
                    </select>
                    <span class="text-red-500 text-xs bg-[#ffffff] font-bold rounded-md ease-in-out text-left max-w-[300px] mt-1 px-3">
                         {{ errors.purpose ? errors.purpose[0] : '' }}
                    </span>
                </div>
                <div class="flex flex-col w-[790px]">
                    <label class="font-bold text-white" for="last_name">Business Email</label>
                    <input placeholder="Enter Business Email" required v-model="FormData.business_email" type="email" class="text-[gray] focus:text-[black] focus:border-[#00AEEE] focus:ring-[#00AEEE] rounded-lg shadow-sm darke:bg-gray-700 darke:border-gray-600 darke:text-white border-[#00AEEE] bg-[#F6F6F6]/80 placeholder:text-[#1E1E1E]/60 px-4 py-2">
                    <span class="text-red-500 text-xs bg-[#ffffff] font-bold rounded-md ease-in-out text-left max-w-[300px] mt-1 px-3">
                         {{ errors.business_email ? errors.business_email[0] : '' }}
                    </span>
                </div>
                <div class="flex flex-col items-start w-[790px] gap-5">
                    <div class="flex gap-5 items-start">
                        <input class="mt-[5px]" required type="checkbox" id="checkbox" v-model="FormData.checkbox">
                        <label class="font-normal text-white text-[15px]" for="checkbox">I have read and accepted the <a href="/terms-condition-privacy-policy" target="_blank" class="font-bold">Terms and Conditions & Privacy Policy </a>| <a href="/end-user-license-agreement" target="_blank" class="font-bold">End User License Agreement</a> by Smicos.com</label>
                    </div>
                    <span v-if="errors.checkbox" class="text-red-500 text-xs bg-[#ffffff] font-bold rounded-md ease-in-out text-left max-w-[800px] mt-1 px-3">
                         {{ errors.checkbox ? errors.checkbox[0] : '' }}
                    </span>
                </div>
                <button @click="registerForMeeting()" class="w-full max-w-[300px] h-16 bg-gradient-to-l from-[#AC191A] to-[#F41815] transform -skew-x-12 text-[30px] md:text-[40px]">
                    <span class="font-bold text-white">Register</span>
                </button>
            </div>

        </div>
        <div v-else  class="flex-col items-center w-full max-w-[1566px] bg-gradient-to-b from-[#2863AA] to-[#03A8E8] flex justify-center p-10">
            <h1 class="text-[2vw] text-white font-bold max-w-[700px] text-center">Thank you for registering. Your exclusive pass will be delivered to you via email.</h1>
        </div>
    </div>
</template>
