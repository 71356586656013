export const inputOptions = () => {
    const ages = [
        "12-27 years old",
        "28-43 years old",
        "44-59 years old",
        "60-69 years old",
        "70-78 years old",
        "79 years old and above"
    ];
    
    const industries = [
        "Agriculture",
        "Apparel & Fashion",
        "Automotive",
        "Banking",
        "Biotechnology",
        "Chemicals",
        "Construction",
        "Consulting",
        "Education",
        "Electronics",
        "Energy",
        "Engineering",
        "Entertainment",
        "Environmental",
        "Finance",
        "Food & Beverage",
        "Government",
        "Healthcare",
        "Hospitality",
        "Insurance",
        "Manufacturing",
        "Marketing",
        "Media",
        "Non-profit",
        "Pharmaceuticals",
        "Real Estate",
        "Retail",
        "Software",
        "Technology",
        "Telecommunications",
        "Transportation",
        "Utilities"
    ];
    
    const locations = [
        "Abra",
        "Agusan del Norte",
        "Agusan del Sur",
        "Aklan",
        "Albay",
        "Antique",
        "Apayao",
        "Aurora",
        "Basilan",
        "Bataan",
        "Batanes",
        "Batangas",
        "Benguet",
        "Biliran",
        "Bohol",
        "Bukidnon",
        "Bulacan",
        "Cagayan",
        "Camarines Norte",
        "Camarines Sur",
        "Camiguin",
        "Capiz",
        "Catanduanes",
        "Cavite",
        "Cebu",
        "Compostela Valley",
        "Cotabato",
        "Davao del Norte",
        "Davao del Sur",
        "Davao Occidental",
        "Davao Oriental",
        "Dinagat Islands",
        "Eastern Samar",
        "Guimaras",
        "Ifugao",
        "Ilocos Norte",
        "Ilocos Sur",
        "Iloilo",
        "Isabela",
        "Kalinga",
        "La Union",
        "Laguna",
        "Lanao del Norte",
        "Lanao del Sur",
        "Leyte",
        "Maguindanao",
        "Marinduque",
        "Masbate",
        "Metro Manila",
        "Misamis Occidental",
        "Misamis Oriental",
        "Mountain Province",
        "Negros Occidental",
        "Negros Oriental",
        "Northern Samar",
        "Nueva Ecija",
        "Nueva Vizcaya",
        "Occidental Mindoro",
        "Oriental Mindoro",
        "Palawan",
        "Pampanga",
        "Pangasinan",
        "Quezon",
        "Quirino",
        "Rizal",
        "Romblon",
        "Samar",
        "Sarangani",
        "Siquijor",
        "Sorsogon",
        "South Cotabato",
        "Southern Leyte",
        "Sultan Kudarat",
        "Sulu",
        "Surigao del Norte",
        "Surigao del Sur",
        "Tarlac",
        "Tawi-Tawi",
        "Zambales",
        "Zamboanga del Norte",
        "Zamboanga del Sur",
        "Zamboanga Sibugay"
    ];
    
    const careers = [
        "Professional",
        "Business Owner",
        "Student"
    ]
    
    const findings = [
        "Social Media (Facebook, Instagram, Twitter, etc.)",
        "Google Search",
        "Blog/Article",
        "Word of Mouth",
        "Other"
    ]
    
    const satisfactions = [
        "Dissatisfied",	
        "Slightly Dissatisfied",	
        "Satisfied",	
        "Very Satisfied"
    ]
    
    const criteria = [
        "Overall mastery of the topic",	
        "Proficiency on the coverage of subject matter",	
        "Credibility as a source speaker",
        "Clarity: Points were made clearly and without unnecessary details",	
        "Pace of delivery"
    ]
    
    const experiences = [
        "I would recommend this event to a friend or colleague.",	
        "My overall webinar experience was very fruitful.",	
        "The time allotted for the event was adequate.",	
        "The webinar ran smoothly and without any disruptions.",	
        "I can confidently say that I have learned something beneficial from this webinar.",	
        "The learnings from this webinar have empowered me to take action in integrating AI into customer management"
    ]

    return {
        ages,
        industries,
        locations,
        careers,
        findings,
        satisfactions,
        criteria,
        experiences
    };
};
