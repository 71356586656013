<template>
    <div class="demo_section">
        <div class="demo_section__details" v-if="!feedback">
            <a href="/"><img class="demo_section__logo" :src="'images/homepage/SmicosLogo-Wordmark.webp'" alt=""></a>

            <span class="demo_section__details__title">
                Intelligent Conversations Amplified: Discover the Smart Integrated Communication Solutions
            </span>
            <span class="demo_section__details__subtitle">
                Harness the power of Data and Artificial
                Intelligence to enhance the customer experience,
                provide personalized assistance and
                streamline various business operations
            </span>
            <div class="demo_section__details__form">
                <v-text-field :error-messages="errors.company_name" v-model="formData.company_name" :append-inner-icon="icons.office" label="Company Name"></v-text-field>
                <v-text-field :error-messages="errors.full_name" v-model="formData.full_name" :append-inner-icon="icons.name" label="Full Name"></v-text-field>
                <v-text-field :error-messages="errors.email" v-model="formData.email" :append-inner-icon="icons.email" type="email" label="Email"></v-text-field>
                <v-text-field :error-messages="errors.contact_no" v-model="formData.contact_no" :append-inner-icon="icons.phoneBold" label="Contact No."></v-text-field>

                <v-checkbox v-model="formData.check" :error-messages="errors.check">
                    <template v-slot:label>
                        <div style="white-space: normal;">
                            I agree to the <a href="/terms-condition-privacy-policy" target="_blank" style="text-decoration: none; color: #00AEEE;">terms and conditions & privacy policy</a> and <a href="/end-user-license-agreement" target="_blank" style="text-decoration: none; color: #00AEEE;">end user license agreement</a>
                        </div>
                    </template>
                </v-checkbox>
                <button class="demo_section__details__form__button g-recaptcha" data-sitekey="6Lfs0UAnAAAAAPCkjAoBvQh1Fu7DY_7Gwflf3E0y" data-callback="onSubmit" @click.prevent="requestDemo">REQUEST DEMO</button>
            </div>
        </div>
        <div class="demo_section__details" v-else>
            <img class="demo_section__logo" :src="'images/homepage/SmicosIcon.webp'" alt="">

            <span class="demo_section__details__title">
                Thank you for your interest with our chatbot solutions!
                We will contact you as soon as possible.
            </span>
        </div>
        <div class="demo_section__imageWrapper">
            <img class="demo_section__imageWrapper__image" :src="'/images/demo/Guy.png'" alt="">
        </div>

    </div>
    <div class="footer_wrapper">
        <div style="display: flex; justify-content: center;">
            <hr class="custom-hr">
        </div>
        <div style="display: flex; justify-content: center;">
            <div class="demo__copyright">
                    <div>
                        Copyright © 2023. Smicos. All rights reserved
                    </div>
                    <div style="text-align: center;">
                        <a href="/terms-condition-privacy-policy" target="_blank" id="privacy-policy">Terms and Conditions & Privacy Policy</a>   |  <a href="/end-user-license-agreement" target="_blank" id="terms-use">End User License Agreement</a>
                    </div>
                    <!-- <div style="text-align: center;">
                        <v-icon color="black" :icon="icons.fb" size="large" style="margin-right: 35px;"></v-icon>
                        <v-icon color="black" :icon="icons.twitter" style="margin-right: 35px;"></v-icon>
                        <v-icon color="black" :icon="icons.yt" size="large" ></v-icon>
                    </div> -->
            </div>
        </div>
    </div>

    <br>
</template>
<script>
import {
    mdiPhoneOutline ,
    mdiMapMarkerOutline,
    mdiOfficeBuilding,
    mdiAccount,
    mdiEmail,
    mdiPhone,
    mdiMessage

} from '@mdi/js';
export default {
props: [
    'recaptchasitekey'
],
components: {
},
data() {
  return {
    app_url: window.app_url,
    email: '',
    icons: {
        phone: mdiPhoneOutline ,
        phoneBold: mdiPhone,
        location: mdiMapMarkerOutline,
        office: mdiOfficeBuilding,
        name: mdiAccount,
        email: mdiEmail,
        message: mdiMessage
    },
    formData: {
        company_name: '',
        full_name: '',
        email: '',
        contact_no: '',
        check: false,
    },
    errors: {},
    feedback: false,
  }
},
created(){
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    this.formData.email = email;
},
mounted(){
},
methods: {
    requestDemo(){
        grecaptcha.execute(this.recaptchasitekey, { action: "send_demo" }).then((token) => {
            axios.post('/send-demo', {
                company_name: this.formData.company_name,
                full_name: this.formData.full_name,
                email: this.formData.email,
                contact_no: this.formData.contact_no,
                check: this.formData.check,
                recaptchaToken: token,
            })

            .then(response => {
                this.formData = {};
                this.feedback = true;
                setTimeout(() => {
                    window.location.href = '/';
                }, 5000);
                // window.open(`${app_url}/register?email=${this.email}`, '_blank');

            })
            .catch(error => {
                this.errors = error.response.data.errors;
                setTimeout(() => {
                    this.errors = {};
                    this.alert.status = false;
                }, 3000);
            });
        });
    }
},
watch: {

},
computed: {

},
}
</script>
