<template>
    <nav class="hidden fixed xl:flex flex-col w-full shadow-lg z-[9999] bg-white">
        <div 
            v-if="isRouteActive('/book-session')"
            class="flex items-center justify-center gap-4 bg-gradient-to-r from-[#0B9DDE] to-[#2F569F] p-2 w-full font-semibold text-white !text-[19px] text-center"
        >
            <img
                class="w-[3rem]"
                :src="`/images/footer/smicos-footer.png`"
                alt="smicos logo"
            >
            <span class="text-sm">
                Unlock Your Potential with a Free 1-on-1 Session!<br>
                Ready to take your business to the next level? Book a personalized session with our experts to explore how Smicos can elevate your strategy, sharpen your digital skills, and help you reach new heights. Let’s create your success story together—book your spot now! 🚀
            </span>
        </div>
        <div class="flex items-center justify-center 2xl:gap-10 gap-4 min-h-[7rem] max-h-[7rem]">
            <div class="w-[70%] flex items-center justify-between">
                <a href="/"><img :src="'/images/homepage/SmicosLogo-Wordmark_blue-R.webp'" class="w-[160px]" alt="Nav Logo"></a>
                <a
                    v-for="nav in navigation"
                    @mouseenter="showSubLinks(nav.title)"
                    @mouseleave="isShowLinks = false" 
                    :href="nav.url"
                    class="relative text-[15px] uppercase xl:w-[7rem] w-[6rem] pb-1.5 text-center hover:text-[#00AEEE]"
                    :class="[isRouteActive(nav.url) ? 'active font-semibold border-b-4 border-[#00AEEE]' : 'font-normal' ]"
                >
                    {{ nav.title }}

                    <div 
                        v-if="nav.title === `Resources` && isShowLinks"
                        class="flex flex-col gap-4 absolute left-1/2 transform -translate-x-1/2 bg-white p-2 w-[8rem] border shadow-lg"
                    >
                        <a
                            v-for="subNav in nav.children"
                            :href="subNav.url"
                            class="relative text-[15px] uppercase xl:w-[7rem] w-[6rem] pb-1.5 text-center hover:text-[#00AEEE]"
                            :class="[isRouteActive(subNav.url) ? 'active font-semibold border-b-4 border-[#00AEEE]' : 'font-normal' ]"
                        >
                            {{ subNav.title }}
                        </a>
                    </div>
                </a>
            </div>
            <div 
                v-if="!storeAuth.auth.id"
                class="relative flex items-center 2xl:gap-10 gap-4"
            >
                <div
                    @click="redirectLogin" 
                    class="2xl:py-[0.75rem] py-[0.5rem] 2xl:w-[10rem] w-[8rem] bg-[#E1251B] text-lg hover:bg-[#E1251B]/80 hover:shadow-lg rounded-full font-semibold text-white text-center cursor-pointer"
                >
                    Log in
                </div>
                <div 
                    @click="redirectRegister"
                    class="2xl:py-[0.75rem] py-[0.5rem] md 2xl:w-[10rem] w-[8rem] border-[1px] border-[#E1251B] text-[#E1251B] text-lg hover:shadow-lg text-center font-semibold rounded-full cursor-pointer"
                >
                    Register
                </div>
                <div class="absolute xxl:-right-[2rem] -right-[1rem] -top-[1rem] font-semibold text-white bg-gradient-to-r from-[#F2CD59] to-[#E37C0D] py-1 px-6 rounded-full">
                    It's Free!
                </div>
            </div>
            <div 
                v-else
                @click="logout()"
                class="text-smicos-secondary font-semibold cursor-pointer hover:underline hover:underline-offset-4 hover:text-smicos-secondary/70 mb-2"   
                target="_blank"
            >
                LOGOUT
            </div>
        </div>
    </nav>

    <!-- <div v-if="!popup" class="xl:hidden block bg-gradient-to-r from-[#0B9DDE] to-[#2F569F] p-2 w-full font-semibold text-white !text-[19px] text-center flex items-center gap-2 justify-center">
        <div>
            <img
                class="2xl:w-[22px] max-sm:hidden"
                :src="`/images/footer/smicos-footer.png`"
                alt="smicos logo"
            >
        </div>
        <span>
            Join us on a free webinar entitled AI Revolution: Transforming Customer Management in Philippine Businesses.
            Limited slots available! <a class="bg-gradient-to-r from-[#E37C0D] to-[#F2CD59] !font-bold bg-clip-text text-transparent !text-[19px]" href="/ai-revolution-transforming-customer-management-in-philippine-businesses-webinar">REGISTER HERE</a> to secure your exclusive access!
        </span>
    </div> -->
    <nav class="2xl:hidden fixed flex items-center w-full min-h-[7rem] max-h-[7rem] border-b-2 px-4 bg-[#FFF] z-10">
        <div class="flex items-center justify-between w-full z-[2]">
            <img :src="'/images/homepage/SmicosLogo-Wordmark_blue-R.webp'" class="w-[8rem]">
            <v-icon @click.stop="drawer = !drawer" color="black" :icon="icons.menu" size="large"></v-icon>
        </div>
        <v-card style="z-index: 9999999 !important;">
            <v-layout>
            <v-navigation-drawer
                v-model="drawer"
            >
                <v-list-item>
                    <div class="mobile-logo">
                        <img width="180" :src="`/images/homepage/SmicosLogo-Wordmark_blue-R.webp`">
                    </div>
                </v-list-item>

                <v-divider></v-divider>

                <v-list density="compact" nav>
                    <a style="cursor: pointer; text-align: left;" :href="'/'" :class="{ active: isRouteActive('/') }"><v-list-item >HOME </v-list-item></a>
                    <a style="cursor: pointer; text-align: left;" :href="'/solutions'" :class="{ active: isRouteActive('/solutions') }"><v-list-item >SOLUTIONS</v-list-item></a>
                    <a style="cursor: pointer; text-align: left;" :href="'/pricing'" :class="{ active: isRouteActive('/pricing') }"><v-list-item >PRICING</v-list-item></a>
                    <a style="cursor: pointer; text-align: left;" :href="'/blogs'" :class="{ active: isRouteActive('/blogs') }"><v-list-item >BLOGS</v-list-item></a>
                    <a style="cursor: pointer; text-align: left;" :href="'/events'" :class="{ active: isRouteActive('/events') }"><v-list-item >EVENTS</v-list-item></a>
                    <a style="cursor: pointer; text-align: left;" :href="'/academy'" :class="{ active: isRouteActive('/academy') }"><v-list-item >ACADEMY</v-list-item></a>
                    <a style="cursor: pointer; text-align: left;" :href="'/about-us'" :class="{ active: isRouteActive('/about-us') }"><v-list-item >ABOUT US</v-list-item></a>
                    <a style="cursor: pointer; text-align: left;" :href="'/contact-us'" :class="{ active: isRouteActive('/contact-us') }"><v-list-item>CONTACT US</v-list-item></a>
                    <a style="cursor: pointer; text-align: left;" :href="'/book-session'" :class="{ active: isRouteActive('/book-session') }"><v-list-item>BOOK SESSION</v-list-item></a>
                </v-list>

                <v-divider></v-divider>

                <v-list density="compact" nav>
                    <v-list-item ><input @click="loginPage" class="action_btn__button1" type="button" value="LOG IN"></v-list-item>
                    <v-list-item ><input @click="demoPage" class="action_btn__button2" type="button" value="REGISTER"></v-list-item>
                </v-list>
            </v-navigation-drawer>
            </v-layout>
        </v-card>
    </nav>
    <!-- <PopupModal
        v-if="showPopup"
        @show-popup="showPopup = false"
    /> -->
</template>
<script>
import { mdiChevronDown, mdiMenu } from '@mdi/js';
import { useAuthStore } from "@/stores/Auth";
import PopupModal from './popup-modal.vue';

export default {
props: [

],
components: {
    PopupModal
},
data() {
  return {
    // showPopup: false,
    storeAuth: useAuthStore(),
    drawer: false,
    isShowLinks: false,
    icons:{
        down: mdiChevronDown,
        menu: mdiMenu
    },
    items: [
        { title: 'Marketing' },
        { title: 'Sales' },
        { title: 'Support' },
        { title: 'Business' },
        { title: 'Healthcare' },
    ],
    navigation: [
        {
            id: 1,
            title: "Home",
            name: "home",
            url: "/",
        },
        {
            id: 2,
            title: "Solutions",
            name: "solutions",
            url: "/solutions",
        },
        {
            id: 3,
            title: "Pricing",
            name: "pricing",
            url: "/pricing",
        },
        {
            id: 4,
            title: "Resources",
            name: "resources",
            children: [
                {
                    id: 1,
                    title: "Blogs",
                    name: "blogs",
                    url: "/blogs",
                },
                // {
                //     id: 2,
                //     title: "Support",
                //     name: "support",
                //     url: "/support",
                // },
                {
                    id: 2,
                    title: "Events",
                    name: "events",
                    url: "/events",
                },
                // {
                //     id: 3,
                //     title: "Academy",
                //     name: "academy",
                //     url: "/academy",
                // },
            ]
        },
        {
            id: 5,
            title: "Academy",
            name: "academy",
            url: "/academy",
        },
        {
            id: 6,
            title: "About Us",
            name: "about-us",
            url: "/about-us",
        },
        {
            id: 7,
            title: "Contact Us",
            name: "contact-us",
            url: "/contact-us",
        },
        {
            id: 8,
            title: "Book Session",
            name: "book-session",
            url: "/book-session",
        },
    ]
  }
},
created(){

},
mounted(){
    const auth = JSON.parse(localStorage.getItem('user')) ?? {};
    this.storeAuth.auth = auth;
    document.addEventListener("click", this.handleClickOutside);
    const popupClosed = localStorage.getItem("isShowPopup");
    switch (popupClosed) {
        case "true":
            return this.showPopup = true;
        case "false":
            return this.showPopup = false;
        default:
            localStorage.setItem("isShowPopup", "true");
            return this.showPopup = true;
    }
},
beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
},
methods: {
    handleClickOutside(event) {
      this.drawer = false;
    },
    isRouteActive(route) {
      return this.currentPath === route;
    },
    redirectLogin() {
        fbq('track', 'ClickLogin');
        window.location.href = 'https://app.smicos.com/login';
    },
    redirectRegister() {
        fbq('track', 'ClickRegister');
        window.location.href = 'https://app.smicos.com/register';
    },
    demoPage() {
    //   this.gtagReportConversion('https://app.smicos.com/register');
        window.location.href = 'https://app.smicos.com/register';
    },
    loginPage() {
        window.location.href = 'https://app.smicos.com';
    },
    gtagReportConversion(url) {
      var callback = function () {
        if (typeof (url) != 'undefined') {
          window.location = url;
        }
      };
      gtag('event', 'conversion', {
        'send_to': 'AW-16472882309/LyjOCO7in5YZEIX58K49',
        'event_callback': callback
      });
      return false;
    },
    showSubLinks(navTitle) {
        if(navTitle === 'Resources'){
            this.isShowLinks = true;
        }

        return;
    },
    logout(){
        localStorage.removeItem("user");
        window.location.href = window.location.href;
    }
},
watch: {

},
computed: {
    currentPath() {
      return window.location.pathname;
    },

    popup() {
        let item = this.showPopup;
        return item;
    }
},
}
</script>