<script setup>
import { ref } from "vue";

const props = defineProps(['academy', 'auth']);

const isEmailResent = ref(false);

const options = {
    headers: {
        Authorization: `Bearer ${props.auth.api_token}`
    }
}

const resentEmail = async () => {
    try {
        const payload = {
                training: {
                id: props.academy.id,
                title: props.academy.title,
                slug: props.academy.slug,
                path: props.academy.fullUrlPath,
                published_date: props.academy.published_date,
            }
        };

        await window.axios.post(`https://app.smicos.com/api/website/sanctum/email-verification-notification`, payload, options);
        isEmailResent.value = true;
    } catch (error) {
        console.error(error);
    }
}
</script>

<template>
    <div class="flex-1 flex justify-center md:p-10 p-6 rounded-md border shadow-md md:max-w-[50rem] w-full">
        <div class="flex flex-col gap-4 md:w-[90%] w-full">
            <img
                class="mx-auto h-[8rem] w-[8rem]"
                src="https://smicos-s3.s3.us-east-2.amazonaws.com/assets/mail_sent.svg"
                alt="Email illustrator"
            >

            <div class="mt-4 mb-4 text-sm">
                <p class="mb-4">
                    Great! You have just created your Smicos account! Now, there's only one step left before you can access the application.
                </p>
                <p>
                    Please verify the email sent to your provided email address. If you don't see it in your inbox, kindly check your spam folder. If you still haven't received it, let us know, and we'll send another one right away!
                </p>
            </div>

            <div    
                v-if="isEmailResent"
                class="text-green-400 text-sm"
            >
                A new verification link has been sent to the email address you provided during registration.
            </div>

            <button 
                @click="resentEmail"
                class="w-full text-white font-bold bg-smicos-primary hover:bg-smicos-primary/70 focus:ring-2 focus:outline-none focus:ring-smicos-secondary rounded-md tracking-widest text-sm px-5 py-2.5 text-center dark:focus:ring-smicos-secondary transition ease-in-out duration-150 uppercase"
            >
                Resend Verification Email
            </button>
        </div>
    </div>
</template>