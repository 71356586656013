<script setup>
import { computed } from "vue";
// Lottie Animation
import { LottieAnimation } from "lottie-web-vue";
import mobileAnimation from "/public/images/lottie/pricing.json";

// Particle JS
import { loadSlim } from "tsparticles-slim";

const particlesInit = async engine => {
    await loadSlim(engine);
};

const options = computed(() => {
    return [
    {
        fpsLimit: 120,
        fullScreen: {
            enable: false
        },
        particles: {
            color: {
                value: '#00AEEE'
            },
            links: {
                color: '#00AEEE',
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1
            },
            collisions: {
                enable: true
            },
            move: {
                direction: 'none',
                enable: true,
                outModes: {
                    default: 'bounce'
                },
                random: false,
                speed: 1,
                straight: false
            },
            number: {
                density: {
                    enable: true,
                    area: 800
                },
                value: 100
            },
            opacity: {
                value: 0.5
            },
            shape: {
                type: 'circle'
            },
            size: {
                value: { min: 1, max: 5 },
            }
        },
        detectRetina: true
    }]
});
</script>

<template>
    <div class="flex flex-col">
        <!-- About Us Banner -->
        <div class="relative flex items-center justify-center gap-6 w-full 2xl:h-[900px] h-[600px]">
            <div class="flex flex-col items-center justify-center md:text-center md:max-w-[50rem] max-w-full max-sm:p-8 z-20">
                <h1 class="text-[#1E1E1E] font-extrabold md:text-[50px] text-[35px] leading-tight">
                    Revolutionize your marketing strategy with our cutting-edge <span class="bg-gradient-to-r from-[#E37C0D] to-[#E1251B] bg-clip-text text-transparent">chatbot AI automation.</span>
                </h1>
            </div>
            <div class="absolute top-0 right-0 w-full h-full z-10">
                <vue-particles
                    id="tsparticles"
                    :particlesInit="particlesInit"
                    :particlesLoaded="particlesLoaded"
                    :options="options"
                    style="height: 100%;"
                />
            </div>
        </div>

        <!-- What is Smicos -->
        <div class="flex items-center justify-center bg-gradient-to-r from-[#F2CD59] to-[#E37C0D] md:p-[5rem] p-[2rem]">
            <div class="flex md:flex-row flex-col md:gap-20 gap-4 md:max-w-[80%]">
                <img 
                    :src="'/images/homepage/SmicosLogo-Wordmark_blue-R.webp'" 
                    class="md:w-[50%] h-[90%]"
                    alt="Smicos logo" 
                />
                <div class="flex flex-col gap-4">
                    <span class="font-extrabold md:text-[35px] text-[30px] text-white">
                        What is Smicos?
                    </span>
                    <p class="text-white text-lg text-justify">
                        Smicos (Smart Integrated Communication Solutions) is an all-in-one chatbot facility powered by PurpleBug. It is an easy-to-use and smart chatbot application that uses artificial intelligence, is highly scalable, and has a modular structure, with easy integrations on different social media platforms. It was created by PurpleBug from the ground up to optimize efficiency, provide around-the-clock availability, and enable exceptional service delivery that drives long-term success for businesses.
                    </p>
                </div>
            </div>
        </div>

        <!-- Who is PurpleBug -->
        <div class="flex items-center justify-center md:p-[5rem] p-[2rem]">
            <div class="flex md:flex-row flex-col gap-20 md:max-w-[80%]">
                <div class="flex flex-col gap-4 md:w-[50%]">
                    <span class="font-extrabold text-center text-[35px]">
                        Who is PurpleBug?
                    </span>
                    <p class="text-lg text-justify">
                        PurpleBug is a top digital marketing agency offering complete solutions for brands, specializing in web and mobile development. With over a decade of experience, they provide high-quality and cost-effective services, including web design, Search Engine Optimization, online advertising, social media management, PR media, and app development.
                    </p>
                </div>
                <div class="flex items-center justify-center md:w-[50%]">
                    <img
                        :src="'/images/about-us/purplebug-logo.svg'"
                        class="md:w-[60%]"
                        alt="Smicos logo"
                    />
                </div>
            </div>
        </div>
    </div>
</template>