import { defineStore, acceptHMRUpdate } from "pinia";

import { ref, reactive } from "vue";

export const useStore = defineStore("Store", () => {
    const getMaxId = (items) => Math.max(...items.map((x) => x.id), 0) + 1;
    
    const toasts = reactive([]);

    const flash = (message, level = "success") => {
        const id = getMaxId(toasts);
        toasts.push({ id, message, level });

        setTimeout(() => {
            toasts = toasts.filter((toast) => toast.id !== id);
        }, 2500);
    };
    const flashWarning = (message) => flash(message, "warning");
    const flashDanger = (message) => flash(message, "danger");

    const closeToast = (toast) => {
        toasts = toasts.filter(({ id }) => id !== toast.id);
    };

    return {
        toasts,

        flash,
        flashWarning,
        flashDanger,
        closeToast
    };
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useStore, import.meta.hot));
}
