<template>
<div class="pt-[10rem] pb-[5rem] px-[20px]">
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:center;"><strong><span style="font-family:  Gotham, sans-serif;">END USER LICENSE AGREEMENT</span></strong></p>
<p style="margin-top:14pt; margin-bottom:14pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;">This End-User License Agreement (&ldquo;EULA&rdquo;) governs your use of PurpleBug, Inc.&rsquo;s (&ldquo;PurpleBug&rdquo;) services, including PurpleBug&rsquo;s business of providing consulting, services, and platforms to help businesses integrate sales, marketing, customer service, and IT operations (the &ldquo;Services&rdquo;).&nbsp;</span></p>
<p style="margin-top:14pt; margin-bottom:14pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;">This EULA puts forth the basis on which PurpleBug makes the Services available to the Customer and/or any further end-user (collectively, &ldquo;Customer&rdquo;). PurpleBug&rsquo;s Terms of Use, Data Processing and Privacy Policy, and Cookie Policy form part of this EULA. This EULA is deemed read into and forms part of any other agreement that may be entered into between Customer and PurpleBug.</span></p>
<p style="margin-top:14pt; margin-bottom:14pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;">While the exact nature of the Services availed of may vary for each Customer, this EULA shall continue to apply and shall not be deemed restricted in any manner.</span></p>
<ol type="1" class="awlist1" style="margin:0pt; padding-left:0pt;">
    <li style="margin-left: 18pt; text-indent: -18pt; text-align: justify; font-family:  Gotham, sans-serif; font-weight: bold;"><span style="width: 9.17pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>GRANT OF SERVICES</li>
</ol>
<p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;"><strong>&nbsp;</strong></span></p>
<ol type="1" class="awlist2" style="margin:0pt; padding-left:0pt;">
    <li style="margin-left: 39.6pt; text-indent: -18.6pt; text-align: justify; font-family:  Gotham, sans-serif;"><span style="width: 3.94pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;</span>PurpleBug shall provide easy to use and smart chatbot solutions and other services. These Services are subject to these terms and conditions.</li>
</ol>
<p style="margin-top:0pt; margin-left:39.6pt; margin-bottom:0pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;"><strong>&nbsp;</strong></span></p>
<ol start="2" type="1" class="awlist3" style="margin:0pt; padding-left:0pt;">
    <li style="margin-left: 39.6pt; text-indent: -21.6pt; text-align: justify; font-family:  Gotham, sans-serif;"><span style="width: 3.94pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;</span>The Customer is hereby granted a license to use, avail of, utilize, and/or or install (collectively, &ldquo;use&rdquo;) the Services (the &ldquo;License&rdquo;), in line with and until the termination of any service or other relevant agreement between PurpleBug and Customer.</li>
</ol>
<p style="margin-top:0pt; margin-left:39.6pt; margin-bottom:0pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;"><strong>&nbsp;</strong></span></p>
<ol start="3" type="1" class="awlist4" style="margin:0pt; padding-left:0pt;">
    <li style="margin-left: 39.6pt; text-indent: -21.6pt; text-align: justify; font-family:  Gotham, sans-serif;"><span style="width: 3.94pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;</span>The Services are owned by PurpleBug, including all intellectual property rights thereof to the fullest extent possible as law or regulation allow. Where the Services are sold to Customer, it is understood that intellectual property rights are retained by PurpleBug, and cannot be exploited by Customer except to use the same in accord with agreements with PurpleBug.</li>
</ol>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt;"><span style="font-family:  Gotham, sans-serif;">&nbsp;</span></p>
<ol start="4" type="1" class="awlist5" style="margin:0pt; padding-left:0pt;">
    <li style="margin-left: 39.6pt; text-indent: -21.6pt; text-align: justify; font-family:  Gotham, sans-serif;"><span style="width: 3.94pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;</span>Customer hereby acknowledges and agrees that the provision of the Services may change due to any limitations, restrictions, or new regulations that may be issued or promulgated by governmental bodies or agencies.</li>
</ol>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt;"><span style="font-family:  Gotham, sans-serif;"><strong>&nbsp;</strong></span></p>
<ol start="5" type="1" class="awlist6" style="margin:0pt; padding-left:0pt;">
    <li style="margin-left: 39.6pt; text-indent: -21.6pt; text-align: justify; font-family:  Gotham, sans-serif;"><span style="width: 3.94pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;</span>PurpleBug reserves the right to revise the terms and conditions of this EULA, providing notice to Customer within a reasonable time thereafter.</li>
</ol>
<p style="margin-top:0pt; margin-left:39.6pt; margin-bottom:0pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;">&nbsp;</span></p>
<ol start="6" type="1" class="awlist7" style="margin:0pt; padding-left:0pt;">
    <li style="margin-left: 39.6pt; text-indent: -21.6pt; text-align: justify; font-family:  Gotham, sans-serif;"><span style="width: 3.94pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;</span>Customer understands that if the Services would include the provision of products or other works from third-party providers, PurpleBug makes no warranty over these products or other works, and Customer agreed to be bound by any conditions, agreements, or obligations from these third-party providers.</li>
</ol>
<p style="margin-top:0pt; margin-left:39.6pt; margin-bottom:0pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;">&nbsp;</span></p>
<ol style="margin:0pt; padding-left:0pt;">
    <li style="margin-left: 38pt; text-indent: -21.6pt; text-align: justify; font-family:  Gotham, sans-serif;"><span style="width: 3.94pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;</span>1.7 Please note that all stored data will be retained for at least three months, after which it may be managed or deleted according to our policies.</li>
</ol>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt;"><span style="font-family:  Gotham, sans-serif;">&nbsp;</span></p>
<p style="margin-top:0pt; margin-left:61.2pt; margin-bottom:0pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;">&nbsp;</span></p>
<ol start="2" type="1" class="awlist8" style="margin:0pt; padding-left:0pt;">
    <li style="margin-left: 18pt; text-indent: -18pt; text-align: justify; font-family:  Gotham, sans-serif; font-weight: bold;"><span style="width: 9.17pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>CUSTOMER&rsquo;S RESPONSIBILITIES</li>
</ol>
<p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;">&nbsp;</span></p>
<ol type="1" class="awlist9" style="margin:0pt; padding-left:0pt;">
    <li style="margin-left: 39.6pt; text-indent: -21.6pt; text-align: justify; font-family:  Gotham, sans-serif;"><span style="width: 3.94pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;</span>Customer shall ensure that the Services are used in accord with all applicable laws and regulations.&nbsp; Customer warrants that it is, and shall continue to be, the legal and beneficial owner of or has, and will continue to have, the legal right to use the Services.</li>
</ol>
<p style="margin-top:0pt; margin-left:39.6pt; margin-bottom:0pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;">&nbsp;</span></p>
<ol start="2" type="1" class="awlist10" style="margin:0pt; padding-left:0pt;">
    <li style="margin-left: 42.6pt; text-indent: -25.6pt; text-align: justify; font-family:  Gotham, sans-serif;"><span style="width: 3.94pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;</span>The Customer shall not, directly or indirectly tamper, decompile, reverse engineer, sell, or reproduce the Services or any part thereof. The Customer will also not infringe on any intellectual property rights owned by or attributable to PurpleBug through the Services, nor shall Customer produce derivative works from the same in a manner that impugns the intellectual property rights of PurpleBug.</li>
</ol>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;">&nbsp;</span></p>
<p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;"><strong>&nbsp;</strong></span></p>
<ol start="3" type="1" class="awlist11" style="margin:0pt; padding-left:0pt;">
    <li style="margin-left: 18pt; text-indent: -18pt; text-align: justify; font-family:  Gotham, sans-serif; font-weight: bold;"><span style="width: 9.17pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>TAXES</li>
</ol>
<p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;"><strong>&nbsp;</strong></span></p>
<p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;">3.1</span><span style="width: 3.27pt; font-family:  Gotham, sans-serif; display: inline-block;">&nbsp;</span><span style="font-family:  Gotham, sans-serif;">Unless shown otherwise and as applicable, the prices quoted are inclusive of the Value Added Tax.</span></p>
<p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-align:justify;"></p>
<p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;"><strong>&nbsp;</strong></span></p>
<ol style="margin:0pt; padding-left:0pt;list-style-type: none;">
    <li style="margin-left: 37.6pt; text-indent: -20.6pt; text-align: justify; font-family:  Gotham, sans-serif;"><span style="font-family:  Gotham, sans-serif;">3.2</span><span style="width: 3.27pt; font-family:  Gotham, sans-serif; display: inline-block;">&nbsp;</span><span style="font-family:  Gotham, sans-serif;">Each party shall be liable for its own taxes arising out of or in connection with the Services, as may be levied against it by the relevant taxing authority and in accord with law. Where Customer is required to withhold any amount from payments to Purplebug, Customer agrees, as Purplebug may request, to gross up the payable amount to Purplebug so as to keep intact the intended cost for the Services as agreed upon.</span></li>
</ol>
<br>
<ol start="4" type="1" class="awlist12" style="margin:0pt; padding-left:0pt;">
    <li style="margin-left: 18pt; text-indent: -18pt; text-align: justify; font-family:  Gotham, sans-serif; font-weight: bold;"><span style="width: 9.17pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>BILLING AND PAYMENTS</li>
</ol>
<br>
<ol style="margin:0pt; padding-left:0pt;list-style-type: none;">
    <li style="margin-left: 37.6pt; text-indent: -20.6pt; text-align: justify; font-family:  Gotham, sans-serif;"><span style="font-family:  Gotham, sans-serif;">4.1 Customer shall settle the entire service fee as billed by Purplebug, in accordance with the terms provided in the billing or as Purplebug may communicate to Customer.</span></li>
</ol>
<p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;"><strong>&nbsp;</strong></span></p>
<p style="margin-top:0pt; margin-bottom:0pt;"><span style="font-family:  Gotham, sans-serif;"><strong>&nbsp;</strong></span></p>
<ol start="5" type="1" class="awlist13" style="margin:0pt; padding-left:0pt;">
    <li style="margin-left: 18pt; text-indent: -18pt; text-align: justify; font-family:  Gotham, sans-serif; font-weight: bold;"><span style="width: 9.17pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>TERM AND TERMINATION</li>
</ol>
<p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;"><strong>&nbsp;</strong></span></p>
<ol type="1" class="awlist14" style="margin:0pt; padding-left:0pt;">
    <li style="margin-left: 39.6pt; text-indent: -21.6pt; text-align: justify; font-family:  Gotham, sans-serif;"><span style="width: 3.94pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;</span>This EULA becomes effective upon use of the Services by Customer. This EULA terminates when the use of the Services concludes, or upon the termination of the service agreement with Customer for any reason.</li>
</ol>
<p style="margin-top:0pt; margin-left:39.6pt; margin-bottom:0pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;">&nbsp;</span></p>
<ol start="2" type="1" class="awlist15" style="margin:0pt; padding-left:0pt;">
    <li style="margin-left: 39.6pt; text-indent: -25.6pt; text-align: justify; font-family:  Gotham, sans-serif;"><span style="width: 3.94pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;</span>Should Customer pre-terminate the Services, all payments made by Customer shall be forfeited in Purplebug&rsquo;s favor. At its sole discretion however, Purplebug may, but is not obliged to, return to Customer any portion of unused payments in view of the pre-termination by Customer.</li>
</ol>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt;"><span style="font-family:  Gotham, sans-serif;">&nbsp;</span></p>
<ol start="3" type="1" class="awlist16" style="margin:0pt; padding-left:0pt;">
    <li style="margin-left: 42.6pt; text-indent: -25.6pt; text-align: justify; font-family:  Gotham, sans-serif;"><span style="width: 3.94pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;</span>Purplebug shall return to Customer all unused payments if Purplebug pre-terminates the services. Payment shall be returned to Customer not more than ninety (90) days from termination by Purplebug of the Services.</li>
</ol>
<p style="margin-top:0pt; margin-left:39.6pt; margin-bottom:0pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;"><strong>&nbsp;</strong></span></p>
<ol start="6" type="1" class="awlist17" style="margin:0pt; padding-left:0pt;">
    <li style="margin-left: 18pt; text-indent: -18pt; text-align: justify; font-family:  Gotham, sans-serif; font-weight: bold;"><span style="width: 9.17pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>WARRANTIES and INDEMNIFICATION</li>
</ol>
<p style="margin-top:0pt; margin-left:39.6pt; margin-bottom:0pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;"><strong>&nbsp;</strong></span></p>
<ol type="1" class="awlist18" style="margin:0pt; padding-left:0pt;">
    <li style="margin-left: 39.6pt; text-indent: -21.6pt; text-align: justify; font-family:  Gotham, sans-serif;"><span style="width: 3.94pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;</span>Except where otherwise provided by law or regulation applicable to PurpleBug, PurpleBug makes no warranty of any kind, express or implied, as regards the Services. Customer&rsquo;s use of the Services is done so at the Customer&rsquo;s own risk.</li>
</ol>
<p style="margin-top:0pt; margin-left:61.2pt; margin-bottom:0pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;">&nbsp;</span></p>
<ol start="2" type="1" class="awlist19" style="margin:0pt; padding-left:0pt;">
    <li style="margin-left: 42.6pt; text-indent: -25.6pt; text-align: justify; font-family:  Gotham, sans-serif;"><span style="width: 3.94pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;</span>To the fullest extent allowed by law or regulation, PurpleBug shall not be liable for any kind of damage whatsoever that may be suffered by Customer in the course of using the Services.&nbsp;</li>
</ol>
<p style="margin-top:0pt; margin-left:61.2pt; margin-bottom:0pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;">&nbsp;</span></p>
<ol start="3" type="1" class="awlist20" style="margin:0pt; padding-left:0pt;">
    <li style="margin-left: 42.6pt; text-indent: -25.8pt; text-align: justify; font-family:  Gotham, sans-serif;"><span style="width: 3.94pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;</span>Customer agrees to indemnify PurpleBug for any claim, damage, or suit made or filed against PurpleBug for negligence attributable to Customer in the course of its use of the Services.</li>
</ol>
<p style="margin-top:0pt; margin-left:61.2pt; margin-bottom:0pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;">&nbsp;</span></p>
<ol start="7" type="1" class="awlist21" style="margin:0pt; padding-left:0pt;">
    <li style="margin-left: 18pt; text-indent: -18pt; text-align: justify; font-family:  Gotham, sans-serif; font-weight: bold;"><span style="width: 9.17pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>MODIFICATION OF SERVICES</li>
</ol>
<p style="margin-top:0pt; margin-left:39.6pt; margin-bottom:0pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;"><strong>&nbsp;</strong></span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;">PurpleBug reserves the right to modify the Services as it may deem fit, as may be required by law or in order to improve the Services. This modification shall be subject to PurpleBug&rsquo;s sole discretion. Customer undertakes to review the EULA or any other agreement between it and PurpleBug from time to time, to keep it duly informed of any changes to the same. Since this EULA is made publicly available, the Customer has the responsibility to ensure that it remains up to date with changes therein.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;">&nbsp;</span></p>
<ol start="8" type="1" class="awlist22" style="margin:0pt; padding-left:0pt;">
    <li style="margin-left: 18pt; text-indent: -18pt; text-align: justify; font-family:  Gotham, sans-serif; font-weight: bold;"><span style="width: 9.17pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>LIMITATIONS OF LIABILITY</li>
</ol>
<p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;"><strong>&nbsp;</strong></span></p>
<ol type="1" class="awlist23" style="margin:0pt; padding-left:0pt;">
    <li style="margin-left: 36pt; text-indent: -18pt; text-align: justify; line-height: 19pt; font-family:  Gotham, sans-serif;"><span style="width: 3.27pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; display: inline-block;">&nbsp;&nbsp;</span>Customer holds Purplebug, and the latter&rsquo;s employees and agents, free and harmless against any and all claims, loss, damages, costs, and expenses of any nature, such as but not limited to costs of suit, loss of income, and attorney&rsquo;s fees, which may be instituted by any party against Purplebug, and/or its employees or agents, arising directly or indirectly from the use by Customer of the Services.</li>
</ol>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; line-height:12pt;"><span style="font-family:  Gotham, sans-serif;">&nbsp;</span></p>
<ol start="2" type="1" class="awlist24" style="margin:0pt; padding-left:0pt;">
    <li style="margin-left: 40pt; text-indent: -22pt; text-align: justify; line-height: 19pt; font-family:  Gotham, sans-serif;"><span style="width: 3.27pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; display: inline-block;">&nbsp;&nbsp;</span>Customer shall indemnify Purplebug for any loss or damage caused to the property of Purplebug, and for any loss or damage suffered by Purplebug, brought about by the use of Customer of the Services.</li>
</ol>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:10pt; text-align:justify; line-height:12pt;"><span style="font-family:  Gotham, sans-serif;">&nbsp;</span></p>
<ol start="3" type="1" class="awlist25" style="margin:0pt; padding-left:0pt;">
    <li style="margin-left: 36pt; margin-bottom: 10pt; text-indent: -22pt; text-align: justify; line-height: 19pt; font-family:  Gotham, sans-serif;"><span style="width: 3.27pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; display: inline-block;">&nbsp;&nbsp;</span>Customer hereby agrees to indemnify and hold harmless Purplebug, its employees, and agents against liability for any injury or death of any personnel of Customer while using the Services, as well as with respect to all loss or damage to their personal effects.</li>
    <li style="margin-left: 38pt; margin-bottom: 10pt; text-indent: -23pt; text-align: justify; line-height: 19pt; font-family:  Gotham, sans-serif;"><span style="width: 3.27pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; display: inline-block;">&nbsp;&nbsp;</span>Customer shall indemnify and hold Pureplebug free and harmless against any claim or action, resulting from Customer&rsquo;s fault or negligence, or a claim brought about by its use of the Services. This includes, but is not limited to, claims or actions for infringement or unfair competition that may be brought/filed by any person or entity against Purplebug, and/or security related claims arising from Customer&rsquo;s failure to conform to pertinent procedures and protocols necessary for the Services (e.g., hacking, data breaches, cloud related claims, loss of data, expiry of file storage duration, etc). This includes all reasonable defense fees, expenses and legal costs (including attorney&rsquo;s fees), or any other costs/claims by the claimant, resulting from a judgment award rendered with finality in favor of such claimant and against Purplebug.</li>
    <li style="margin-left: 36pt; margin-bottom: 10pt; text-indent: -23pt; text-align: justify; line-height: 19pt; font-family:  Gotham, sans-serif;"><span style="width: 3.27pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; display: inline-block;">&nbsp;&nbsp;</span>Without limiting the generality of the foregoing, Customer specifically undertakes to defend and hold Purplebug free and harmless against any liability, loss, or damage (including reasonable legal fees) resulting from any claim of any personnel of Customer seeking to be treated as an employee of Purplebug or of the latter&apos;s clients, which shall include but not be limited to claims in relation to payment of salary, workers&apos; compensation, fringe benefits, disability income, social security, or any other benefits or entitlements under the Labor Code, income tax withholding at source, or for any other purpose.</li>
</ol>
<p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;"><strong>&nbsp;</strong></span></p>
<ol start="9" type="1" class="awlist26" style="margin:0pt; padding-left:0pt;">
    <li style="margin-left: 18pt; text-indent: -18pt; text-align: justify; font-family:  Gotham, sans-serif; font-weight: bold;"><span style="width: 9.17pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>MISCELLANEOUS</li>
</ol>
<p style="margin-top:0pt; margin-left:39.6pt; margin-bottom:0pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;"><strong>&nbsp;</strong></span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;">Should any provision of this EULA be declared with finality by competent authority to be invalid, illegal, or unenforceable, provisions not affected by such declaration shall remain in full force and effect.</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;">No failure or delay on the part of PurpleBug to exercise its rights under this EULA or any other agreement with Customer shall be considered as a waiver of any such right.&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;">&nbsp;</span></p>
<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;"><span style="font-family:  Gotham, sans-serif;">This EULA shall be governed by the laws of the Republic of the Philippines. Any claim arising from or related to this EULA shall be brought solely in the courts of the City of Makati, Philippines, to the exclusion of all other courts of venues.</span></p>
<p style="bottom: 10px; right: 10px; position: absolute;"><a href="https://wordtohtml.net" target="_blank" style="font-size:11px; color: #d0d0d0;"><span style="font-family:  Gotham, sans-serif;">Converted to HTML with WordToHTML.net</span></a></p>
</div>
</template>
<style type="text/css">
    .awlist1 {
        list-style: none;
        counter-reset: awlistcounter2_0
    }

    .awlist1>li:before {
        content: counter(awlistcounter2_0) '.';
        counter-increment: awlistcounter2_0
    }

    .awlist2 {
        list-style: none;
        counter-reset: awlistcounter2_1
    }

    .awlist2>li:before {
        content: '1.'counter(awlistcounter2_1) '.';
        counter-increment: awlistcounter2_1
    }

    .awlist3 {
        list-style: none;
        counter-reset: awlistcounter2_1 1
    }

    .awlist3>li:before {
        content: '1.'counter(awlistcounter2_1) '.';
        counter-increment: awlistcounter2_1
    }

    .awlist4 {
        list-style: none;
        counter-reset: awlistcounter2_1 2
    }

    .awlist4>li:before {
        content: '1.'counter(awlistcounter2_1) '.';
        counter-increment: awlistcounter2_1
    }

    .awlist5 {
        list-style: none;
        counter-reset: awlistcounter2_1 3
    }

    .awlist5>li:before {
        content: '1.'counter(awlistcounter2_1) '.';
        counter-increment: awlistcounter2_1
    }

    .awlist6 {
        list-style: none;
        counter-reset: awlistcounter2_1 4
    }

    .awlist6>li:before {
        content: '1.'counter(awlistcounter2_1) '.';
        counter-increment: awlistcounter2_1
    }

    .awlist7 {
        list-style: none;
        counter-reset: awlistcounter2_1 5
    }

    .awlist7>li:before {
        content: '1.'counter(awlistcounter2_1) '.';
        counter-increment: awlistcounter2_1
    }

    .awlist8 {
        list-style: none;
        counter-reset: awlistcounter2_0 1
    }

    .awlist8>li:before {
        content: counter(awlistcounter2_0) '.';
        counter-increment: awlistcounter2_0
    }

    .awlist9 {
        list-style: none;
        counter-reset: awlistcounter2_1
    }

    .awlist9>li:before {
        content: '2.'counter(awlistcounter2_1) '.';
        counter-increment: awlistcounter2_1
    }

    .awlist10 {
        list-style: none;
        counter-reset: awlistcounter2_1 1
    }

    .awlist10>li:before {
        content: '2.'counter(awlistcounter2_1) '.';
        counter-increment: awlistcounter2_1
    }

    .awlist11 {
        list-style: none;
        counter-reset: awlistcounter2_0 2
    }

    .awlist11>li:before {
        content: counter(awlistcounter2_0) '.';
        counter-increment: awlistcounter2_0
    }

    .awlist12 {
        list-style: none;
        counter-reset: awlistcounter2_0 3
    }

    .awlist12>li:before {
        content: counter(awlistcounter2_0) '.';
        counter-increment: awlistcounter2_0
    }

    .awlist13 {
        list-style: none;
        counter-reset: awlistcounter2_0 4
    }

    .awlist13>li:before {
        content: counter(awlistcounter2_0) '.';
        counter-increment: awlistcounter2_0
    }

    .awlist14 {
        list-style: none;
        counter-reset: awlistcounter2_1
    }

    .awlist14>li:before {
        content: '5.'counter(awlistcounter2_1) '.';
        counter-increment: awlistcounter2_1
    }

    .awlist15 {
        list-style: none;
        counter-reset: awlistcounter2_1 1
    }

    .awlist15>li:before {
        content: '5.'counter(awlistcounter2_1) '.';
        counter-increment: awlistcounter2_1
    }

    .awlist16 {
        list-style: none;
        counter-reset: awlistcounter2_1 2
    }

    .awlist16>li:before {
        content: '5.'counter(awlistcounter2_1) '.';
        counter-increment: awlistcounter2_1
    }

    .awlist17 {
        list-style: none;
        counter-reset: awlistcounter2_0 5
    }

    .awlist17>li:before {
        content: counter(awlistcounter2_0) '.';
        counter-increment: awlistcounter2_0
    }

    .awlist18 {
        list-style: none;
        counter-reset: awlistcounter2_1
    }

    .awlist18>li:before {
        content: '6.'counter(awlistcounter2_1) '.';
        counter-increment: awlistcounter2_1
    }

    .awlist19 {
        list-style: none;
        counter-reset: awlistcounter2_1 1
    }

    .awlist19>li:before {
        content: '6.'counter(awlistcounter2_1) '.';
        counter-increment: awlistcounter2_1
    }

    .awlist20 {
        list-style: none;
        counter-reset: awlistcounter2_1 2
    }

    .awlist20>li:before {
        content: '6.'counter(awlistcounter2_1) '.';
        counter-increment: awlistcounter2_1
    }

    .awlist21 {
        list-style: none;
        counter-reset: awlistcounter2_0 6
    }

    .awlist21>li:before {
        content: counter(awlistcounter2_0) '.';
        counter-increment: awlistcounter2_0
    }

    .awlist22 {
        list-style: none;
        counter-reset: awlistcounter2_0 7
    }

    .awlist22>li:before {
        content: counter(awlistcounter2_0) '.';
        counter-increment: awlistcounter2_0
    }

    .awlist23 {
        list-style: none;
        counter-reset: awlistcounter6_1
    }

    .awlist23>li:before {
        content: '8.'counter(awlistcounter6_1);
        counter-increment: awlistcounter6_1
    }

    .awlist24 {
        list-style: none;
        counter-reset: awlistcounter6_1 1
    }

    .awlist24>li:before {
        content: '8.'counter(awlistcounter6_1);
        counter-increment: awlistcounter6_1
    }

    .awlist25 {
        list-style: none;
        counter-reset: awlistcounter6_1 2
    }

    .awlist25>li:before {
        content: '8.'counter(awlistcounter6_1);
        counter-increment: awlistcounter6_1
    }

    .awlist26 {
        list-style: none;
        counter-reset: awlistcounter2_0 8
    }

    .awlist26>li:before {
        content: counter(awlistcounter2_0) '.';
        counter-increment: awlistcounter2_0
    }
</style>
