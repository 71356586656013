<script setup>
import {
    CalendarIcon,
    CalendarPlusIcon,
    ClockIcon,
    MapPinIcon,
    MegaphoneIcon
} from "@components/icons";

import EventsUpcomingCard from "@components/events/EventsUpcomingCard.vue";

const props = defineProps(['event']);

// utils
const formatDate = (date) => {
    return moment(date).format("MMMM DD, YYYY, ddd");
}

const formatTime = (start, end) => {
    return moment(start).format("hh:mm A")+" - "+moment(end).format("hh:mm A")
}
</script>

<template>
    <div class="flex flex-col">
        <!-- banner -->
        <div 
            class="relative flex items-center justify-center 2xl:h-[900px] lg:h-[750px] h-[600px] bg-[#0A9EDF]"
            style="background: rgba(10, 158, 223, 1) url('/images/events/banner/banner-1.png') no-repeat; background-size: cover; background-position: center;" 
        >
            <div class="flex flex-col items-center justify-center gap-4 max-w-[65rem] text-center">
                <h1 class="text-[64px] leading-tight font-extrabold text-white">
                    Better Conversations, Better Business
                </h1>
                <span class="text-[#8F8F8F] text-[24px] text-white">
                    Join our webinars and learn how AI-powered chatbot can elevate customer management and drive business results. Register now and start building better customer relationships!
                </span>
            </div>

            <!-- float figures -->
            <img :src="`/images/figures/particles-left.png`" class="absolute left-0 top-0"/>
            <img :src="`/images/figures/particles-right.png`" class="absolute right-0 -bottom-20"/>
            <img :src="`/images/figures/circle-blue.png`" class="absolute -right-[20rem] -top-[20rem]"/>
            <img :src="`/images/figures/circle-blue.png`" class="absolute -left-[20rem] -bottom-[20rem]"/>
        </div>

        <!-- calendar section -->
        <div class="flex justify-center p-20 bg-gradient-to-r from-[#0A9EDF] to-[#31549D] z-[2]">
            <div class="flex items-center justify-between w-full max-w-[80%]">
                <div class="flex flex-col gap-4 text-white min-w-[15rem]">
                    <div class="flex items-center gap-2">
                        <CalendarIcon class="w-6"/>
                        <span>
                            Date
                        </span>
                    </div>
                    <div class="font-bold">
                        {{ formatDate(event.start_at) }}
                    </div>
                    <div class="h-[1px] bg-white"></div>
                </div>
                <div class="flex flex-col gap-4 text-white min-w-[15rem]">
                    <div class="flex items-center gap-2">
                        <ClockIcon class="w-6"/>
                        <span>
                            Time
                        </span>
                    </div>
                    <div class="font-bold">
                        {{ formatTime(event.start_at, event.end_at) }}
                    </div>
                    <div class="h-[1px] bg-white"></div>
                </div>
                <div class="flex flex-col gap-4 text-white min-w-[15rem]">
                    <div class="flex items-center gap-2">
                        <MapPinIcon class="w-6"/>
                        <span>
                            Platform
                        </span>
                    </div>
                    <div class="font-bold">
                        Zoom, Facebook Live
                    </div>
                    <div class="h-[1px] bg-white"></div>
                </div>
                <div class="flex flex-col gap-4 text-white min-w-[15rem]">
                    <div class="flex items-center gap-2">
                        <MegaphoneIcon class="w-6"/>
                        <span>
                            Join Now for <b class="text-[#F2CD59]">FREE!</b>
                        </span>
                    </div>
                    <div class="flex items-center gap-4">
                        <div class="flex items-center justify-center w-10 h-10 rounded-full bg-white">
                            <CalendarPlusIcon class="w-6"/>
                        </div>
                        <a 
                            href=""
                            class="py-2 px-6 bg-smicos-secondary rounded-full min-w-[12rem] text-center uppercase font-semibold"
                        >
                            Register
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <!-- title description -->
        <div class="flex justify-center gap-20 p-20">
            <div class="flex items-start justify-between w-full gap-20 max-w-[80%]">
                <div class="flex flex-col gap-4 max-w-[15rem]">
                    <div class="font-extrabold leading-tight text-[28px] bg-gradient-to-r from-[#E1251B] to-[#E37C0D] bg-clip-text text-transparent">
                        {{ event.title }}
                    </div>
                    <div class="h-[1px] bg-gradient-to-r from-[#E1251B] to-[#E37C0D]"></div>
                </div>
                <div class="flex-1">
                    {{ event.description }}
                </div>
            </div>
        </div>

        <!-- key topics -->
        <div class="flex justify-center gap-20 bg-gradient-to-r from-[#F2CD59] to-[#E37C0D] p-20">
            <div class="flex items-start justify-between w-full gap-20 max-w-[80%]">
                <div class="flex flex-col gap-4 max-w-[15rem] min-w-[15rem]">
                    <div class="font-extrabold leading-tight text-[28px] text-white">
                        Key Topics
                    </div>
                    <div class="h-[1px] bg-white"></div>
                </div>
                <div class="flex-1 text-white">
                    {{ event.key_topics }}
                </div>
            </div>
        </div>

        <!-- speakers -->
        <div class="flex justify-center gap-20 p-20">
            <div class="flex items-start justify-between w-full gap-20 max-w-[80%]">
                <div class="flex flex-col gap-4 max-w-[15rem]">
                    <div class="font-extrabold leading-tight text-[28px] bg-gradient-to-r from-[#E1251B] to-[#E37C0D] bg-clip-text text-transparent">
                        Webinar Spearkers
                    </div>
                    <div class="h-[1px] bg-gradient-to-r from-[#E1251B] to-[#E37C0D]"></div>
                </div>
                <div class="flex-1 flex flex-col gap-10">
                    <div v-for="index in 3">
                        <EventsUpcomingCard />
                    </div>
                </div>
            </div>
        </div>

        <!-- short summary -->
        <div class="flex justify-center gap-20 bg-gradient-to-r from-[#F2CD59] to-[#E37C0D] p-20">
            <div class="flex items-start justify-between w-full gap-20 max-w-[80%]">
                <div class="flex flex-col gap-4 max-w-[15rem] min-w-[15rem]">
                    <div class="font-extrabold leading-tight text-[28px] text-white">
                        Short Summary
                    </div>
                    <div class="h-[1px] bg-white"></div>
                </div>
                <div class="flex-1 flex flex-col gap-20">
                    <div class="text-white">
                        {{ event.event_summary }}
                    </div>
                    <div class="flex flex-col gap-4 text-white min-w-[15rem]">
                        <div class="flex items-center gap-2">
                            <MegaphoneIcon class="w-6"/>
                            <span>
                                Join Now for <b class="text-smicos-secondary">FREE!</b>
                            </span>
                        </div>
                        <div class="flex items-center gap-4">
                            <div class="flex items-center justify-center w-10 h-10 rounded-full bg-white">
                                <CalendarPlusIcon class="w-6"/>
                            </div>
                            <a 
                                href=""
                                class="py-2 px-6 bg-smicos-secondary rounded-full min-w-[12rem] text-center uppercase font-semibold"
                            >
                                Register
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>