<script setup>
import { ref, computed } from "vue";
import Card from "@components/includes/Card.vue";

// blogs
const blogs = ref([]);

const getBlogs = async () => {
    try {
        const res = await axios(`/blogs/api/all`);
        blogs.value = res.data;
    } catch (error) {
        console.error(error);
    }
}

getBlogs();
</script>

<template>
    <div class="flex flex-col">
        <!-- banner -->
        <div class="relative flex items-center justify-center 2xl:h-[900px] lg:h-[750px] h-[600px]">
            <div class="flex flex-col items-center justify-center gap-4 max-w-[55rem] text-center">
                <h1 class="text-[64px] leading-tight font-extrabold bg-gradient-to-r from-[#E1251B] to-[#E37C0D] bg-clip-text text-transparent">
                    Stay Informed, Engaged, and Connected.
                </h1>
                <span class="text-[#8F8F8F] text-[24px]">
                    Uncover Events, Trends, and Transformative Insights within the dynamic and ever-evolving technological landscape
                </span>
            </div>

            <!-- float figures -->
            <img :src="`/images/figures/particles-left.png`" class="absolute left-0 top-0"/>
            <img :src="`/images/figures/particles-right.png`" class="absolute right-0 -bottom-20"/>
            <img :src="`/images/figures/circle-orange.png`" class="absolute -right-[20rem] -top-[20rem]"/>
            <img :src="`/images/figures/circle-orange.png`" class="absolute -left-[20rem] -bottom-[20rem]"/>
        </div>
        <!-- blogs -->
        <div class="flex flex-col bg-gradient-to-r from-[#F2CD59] to-[#E37C0D] p-20">
            <div class="flex flex-wrap justify-center gap-20">
                <div v-for="blog in blogs">
                    <Card 
                        :item="blog"
                        buttonText="Learn more"
                        buttonCss="bg-[#00AEEE] hover:bg-[#00AEEE]/80 px-8 py-4"
                    />
                </div>
            </div>
        </div>
    </div>
</template>