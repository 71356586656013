<script setup>
import {
    onMounted,
    ref
} from 'vue';

import {
    closeIcon
} from '../icons';

const emits = defineEmits(["showPopup"]);

const closePop = () => {
    localStorage.setItem("isShowPopup", "false");
    emits("showPopup");
}

const goToLink = () => {
    location.href = "/ai-revolution-transforming-customer-management-in-philippine-businesses-webinar";
    localStorage.setItem("isShowPopup", "false");
    emits("showPopup");
}

const isShowPopup = ref(false);

onMounted(() => {
    setTimeout(() => {
        isShowPopup.value = !isShowPopup.value;
    }, 5000);
});
</script>
<template>
    <Teleport to="#outside">
        <div v-if="isShowPopup" class="fixed z-[999999999] flex justify-center items-start md:pt-[10rem] pt-[6rem] bg-black/20 h-full w-full">
            <div class="relative 2xl:w-[60%] w-[85%] max-h-[70%]">
                <closeIcon  @click="closePop()" class="absolute top-1 right-1 w-[25px] md:w-[50px] cursor-pointer"></closeIcon>
                <img @click="goToLink()" class="w-full h-full cursor-pointer" :src="'/images/pop-up-banner.webp'" alt="">
            </div>
        </div>
    </Teleport>
</template>
