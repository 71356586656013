<script setup>
import { ref, computed } from "vue";
import {
    ArrowLeftIcon
} from "@components/icons";

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import Card from "@components/includes/Card.vue";

const props = defineProps(['blog']);

// utils
const formatDate = (date) => {
    return moment(date).format("MMMM DD, YYYY");
}

// blogs
const blogs = ref([]);

const getBlogs = async () => {
    try {
        const res = await axios(`/blogs/api/all`);
        blogs.value = res.data;
    } catch (error) {
        console.error(error);
    }
}

getBlogs();

const filteredBlogs = computed(() => {
    let items = blogs.value;
    if (items.length < 1) return [];

    const groupedItems = [];
    for (let i = 0; i < items.length; i += 4) {
        groupedItems.push(items.slice(i, i + 4));
    }

    return groupedItems;
})
</script>

<template>
    <div class="relative flex flex-col w-full h-full">
        <div class="flex flex-col w-full h-full">
            <div
                class="relative flex items-center justify-center 2xl:h-[600px] lg:h-[450px] h-[200px] bg-[#0A9EDF]"
                :style="`background: url(${blog.banner}) no-repeat; background-size: cover; background-position: center;`"
            ></div>
            <div class="flex justify-center bg-gradient-to-r from-[#0A9EDF] to-[#31549D] py-20">
                <div class="flex flex-col items-center justify-center gap-10 text-center max-w-[65%]">
                    <a 
                        href="/blogs"
                        class="flex items-center justify-center gap-2 bg-smicos-secondary hover:bg-smicos-secondary/80 rounded-full px-6 py-2 text-white text-center font-bold uppercase max-w-[14rem]"
                    >
                        <ArrowLeftIcon />
                        <span>
                            Back to blogs
                        </span>
                    </a>
                    <h1 class="text-[42px] font-extrabold text-white leading-tight">
                       {{ blog.title ?? "No title" }}
                    </h1>
                    <div class="flex items-center gap-6 text-white">
                        <span>
                            {{ formatDate(blog.published_date) }}
                        </span>
                        <span>
                            |
                        </span>
                        <span>
                            {{ blog.category.category_name }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="flex justify-center py-20">
                <div class="flex items-start max-w-[85%]">
                    <div v-html="blog.content"></div>
                </div>
            </div>
            <div class="flex justify-center bg-gradient-to-r from-[#0A9EDF] to-[#31549D] p-20">
                <div class="flex items-center justify-center gap-10 text-center w-full">
                    <swiper
                        :slides-per-view="1"
                        :pagination="true"
                        class="w-full"
                    >
                        <swiper-slide v-for="(group, index) in filteredBlogs" :key="index">
                            <div 
                                class="flex items-center gap-20"
                                :class="[group.length > 3 ? 'justify-between' : '']"
                            >
                                <div v-for="item in group" :key="blog.id">
                                    <Card
                                        :item="item"
                                        :urlRoute="`/blogs/${item.slug}`"
                                        buttonCss="bg-smicos-secondary hover:bg-smicos-secondary/80 px-3 py-2"
                                        cardCss="min-w-[22rem] max-w-[22rem]"
                                        imageCss="h-[13rem]"
                                    />
                                </div>
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
        </div>
    </div>
</template>