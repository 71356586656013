<script setup>
import {
    Quote2Icon,
    ArrowUpRightIcon
} from "@components/icons";

const props = defineProps(['blog']);

// Utils
const parseTextContent = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html').body.textContent || "";

    const sentences = doc.split('. ');
    const firstSentence = `${sentences[0]}.`;

    if (firstSentence.length > 150) {
      return firstSentence.substring(0, 150) + '...';
    }

    return firstSentence;
}

const formatDate = (date) => {
    return moment(date).format("MMMM DD, YYYY");
}
</script>

<template>
    <div class="relative flex flex-col items-center md:min-w-[30rem] md:max-w-[30rem]">
        <div class="relative md:-bottom-10 -bottom-14 rounded-t-3xl border-[1px] border-[#00AEEE] w-full h-[18rem]">
            <img :src="`${blog.banner}`" alt="card banner" class="h-full w-full rounded-xl object-cover">
        </div>
        <div class="relative w-full max-h-[20.5rem]">
            <Quote2Icon class="w-full h-full" />
            <div class="absolute top-0 left-0 flex flex-col justify-start xs:gap-4 gap-1 md:p-8 p-4 max-sm:text-sm h-[16rem]">
                <span class="text-[20px] font-bold">
                    {{ blog.title }}
                </span>
                <span class="max-xxs:w-[16rem] max-xxs:truncate">
                    {{ parseTextContent(blog.content) }}
                </span>
            </div>
            <div class="relative md:-top-[4.5rem] -top-[2.5rem] flex items-center justify-between md:h-[4.5rem] h-[2.5rem] md:px-[2rem] px-[1rem]">
                <div class="text-[#77777A] max-xxs:text-sm">
                    {{ formatDate(blog.published_date) }}
                </div>
                <a
                    :href="`/blogs/${blog.slug}`"
                    class="absolute bottom-0 right-0 flex md:gap-2 rounded-full cursor-pointer bg-[#E1251B] hover:bg-[#E1251B]/80 md:px-[2rem] px-[0.5rem] md:py-[1rem]"
                >
                    <span class="text-[#FFF] max-sm:text-sm max-xxs:text-xs font-bold max-sm:p-2 uppercase">
                        Read More
                    </span>
                    <ArrowUpRightIcon class="w-5"/>
                </a>
            </div>
        </div>
    </div>
</template>