<template>
<div class="pt-[10rem] pb-[5rem] px-[20px]">
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:12pt; widows:0; orphans:0;"><strong><span style='font-family: "Gotham", courier; font-size: 16px;'>PURPLEBUG TERMS OF USE,&nbsp;</span></strong></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:12pt; widows:0; orphans:0;"><span style='font-size: 16px; font-family: "Gotham", courier;'><strong>DATA PROCESSING and PRIVACY POLICY, AND COOKIE POLICY&nbsp;</strong></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:12pt; widows:0; orphans:0;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>Our Terms of Use, Data Processing and Privacy Policy, and Cookie Policy (collectively, &ldquo;Policy&rdquo;) governs your use of our services as well as how we collect, use, disclose, store, and/or process the personal data that you have provided to us. This data is given in varying ways including, but not limited to, using, accessing, benefitting from, availing of, or in any other matter utilizing (collectively, &ldquo;using&rdquo; or &ldquo;use&rdquo;) PurpleBug, Inc. (&ldquo;PurpleBug&rdquo;) any of its services, systems, platforms, technologies, equipment, offers, or derivatives therefrom (collectively, &ldquo;Services&rdquo;), in compliance with Republic Act No. 10173 or &ldquo;the Data Privacy Act of 2012&rdquo;, its Implementing Rules and Regulations, the issuances of the National Privacy Commission (&ldquo;NPC&rdquo;), and other relevant laws (collectively, &ldquo;law&rdquo;).</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>By transacting with PurpleBug and using its Services, you hereby acknowledge that you have read, understood, and agreed to the terms of Policy. You agree to having your data collected, corrected, transferred, stored, retrieved, used, retained, shared, deconstructed, and/or destroyed in the manner disclosed in this Policy. This Policy applies to the maximum extent possible to all individuals, juridical entities, customers, or any other party that avails of or uses the Services of PurpleBug (collectively, &ldquo;Customer&rdquo;), its affiliates, subsidiaries, or related entities, subject to rights retained by the Customer as required by law. Without limiting the applicability of this Policy, PurpleBug and Customer may enter into more definitive agreements, which shall control in areas not governed by this Policy or where such definitive agreements expressly provide. PurpleBug and Customer may be referred to herein individually as a &ldquo;Party&rdquo; or, collectively, as &ldquo;Parties&rdquo;.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol type="1" class="awlist1" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 16pt; text-indent: -16pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px; font-weight: bold;'><span style="width: 9.17pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><u>TERMS OF USE</u></li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol type="1" class="awlist2" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 39.6pt; text-indent: -21.6pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px; font-weight: bold;'><span style="width: 3.94pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;</span>GENERAL POLICIES</li>
    </ol>
    <p style="margin-top:0pt; margin-left:39.6pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-size: 16px; font-family: "Gotham", courier;'><strong>&nbsp;</strong></span></p>
    <ol type="1" style="margin:0pt; padding-left:0pt; list-style: none;">
        <li style='margin-left: 62pt; text-indent: -32pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>1.1.1 Use of PurpleBug Services shall be with the express written consent of PurpleBug. PurpleBug reserves the right to restrict, deny, alter, or charge for any use of PurpleBug Services without PurpleBug&rsquo;s consent.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="2" type="1" style="margin:0pt; padding-left:0pt; list-style: none;">
        <li style='margin-left: 62pt; text-indent: -36pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>1.1.2 The Customer hereby agrees that any material submitted or entered into PurpleBug&rsquo;s Services by Customer does not violate or infringe upon any copyright, trademark, patent, statutory, common law, or proprietary rights of others or contain anything obscene or libelous, and is not in conflict or in competition with PurpleBug or any PurpleBug affiliates.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="3" type="1" style="margin:0pt; padding-left:0pt; list-style: none;">
        <li style='margin-left: 62pt; text-indent: -36pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>1.1.3 PurpleBug reserves the right to disconnect or deactivate Services to or for any Customer at any time, without any prior notice, in situations where an act or acts attributable to the Customer directly interferes with PurpleBug&rsquo;s capability to provide Services. The Customer must therefore, at all times, comply with PurpleBug requirements regarding the proper use of the Services.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="4" type="1" style="margin:0pt; padding-left:0pt; list-style: none;">
        <li style='margin-left: 62pt; text-indent: -36pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>1.1.4 PurpleBug reserves the right to withhold the release of a Customer&apos;s information to another Customer or to any person or entity, since all applications shall be treated with confidentiality, except where the prior written consent of the Customer is obtained or when such information is required to be disclosed pursuant to any applicable law, legal process, or inquiry issued by any government body or court of law.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="5" type="1"  style="margin:0pt; padding-left:0pt; list-style: none;">
        <li style='margin-left: 62pt; text-indent: -36pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>1.1.5 Customer acknowledges that the Services provided by PurpleBug is of such a nature that service can be interrupted for many reasons other than the negligence of the company, and that damages resulting from any interruption of Services are difficult to ascertain. Therefore, Customer agrees that PurpleBug shall not be liable for any damages arising from such causes not directly and exclusively attributable to PurpleBug. Customer further acknowledges that PurpleBug&rsquo;s liability may not, in any event, exceed an amount equivalent to charges payable by Customer for Services during the period in which damages occurred. PurpleBug shall in no manner be liable for any special or consequential damages, loss, or injury. PurpleBug, at its sole discretion, may decide whether or not credit is due for any loss of Services.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="6" type="1"  style="margin:0pt; padding-left:0pt; list-style: none;">
        <li style='margin-left: 62pt; text-indent: -38pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>1.1.6 Customer understands and accepts that their ability to access and use the Services provided by PurpleBug is contingent upon certain predefined restrictions set by PurpleBug or regulations applicable to it. These limitations may encompass various aspects such as usage duration, frequency, specific features, or legal requirements, and they serve as parameters to ensure a controlled and balanced utilization of the Services.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="6" type="1"  style="margin:0pt; padding-left:0pt; list-style: none;">
        <li style='margin-left: 62pt; text-indent: -38pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>1.1.7 Please note that all stored data will be retained for at least three months, after which it may be managed or deleted according to our policies.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="2" type="1" style="margin:0pt; padding-left:0pt; list-style: none;">
        <li style='margin-left: 39.6pt; text-indent: -21.6pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px; font-weight: bold;'><span style="width: 3.94pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;</span>1.2 PAYMENT TERMS AND SUBSCRIPTION</li>
    </ol>
    <p style="margin-top:0pt; margin-left:39.6pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-size: 16px; font-family: "Gotham", courier;'><strong>&nbsp;</strong></span></p>
    <ol type="1" style="margin:0pt; padding-left:0pt; list-style: none;">
        <li style='margin-left: 62pt; text-indent: -36pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>1.2.1 In consideration of the Services, Customer shall pay to PurpleBug without need of demand the applicable service fees in amounts and terms to be agreed upon. Non-receipt of the applicable service fees by PurpleBug may result in the suspension of the Services of PurpleBug.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-size: 16px; font-family: "Gotham", courier;'><strong>&nbsp;</strong></span></p>
    <ol start="3" type="1" style="margin:0pt; padding-left:0pt; list-style: none;">
        <li style='margin-left: 39.6pt; text-indent: -21.6pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px; font-weight: bold;'><span style="width: 3.94pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;</span>1.3 REPRESENTATIONS AND WARRANTIES</li>
    </ol>
    <p style="margin-top:0pt; margin-left:39.6pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-size: 16px; font-family: "Gotham", courier;'><strong>&nbsp;</strong></span></p>
    <ol type="1" style="margin:0pt; padding-left:0pt; list-style: none;">
        <li style='margin-left: 82px; text-indent: -35pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>1.3.1 PurpleBug and Customer hereby represent and warrant that they have the right, power, and authority, and have taken all actions necessary, to execute, deliver, and exercise their rights and perform their obligations under this Policy, including but not limited to having obtained the necessary approval from its relevant officers and management.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="2" type="1" style="margin:0pt; padding-left:0pt; list-style: none;">
        <li style='margin-left: 82px; text-indent: -39pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>1.3.2 Customer represents that its acceptance of this Policy, use of Services, or the performance or fulfillment of any obligation herein does not and will not conflict with any regulation, agreement, or arrangement to which the Customer is a party to or is bound. Customer hereby warrants that it is not, and will not be, in contravention of any law or administrative or government regulation in availing of the Services or agreeing to this Policy, nor will such use infringe or impugn any intellectual property.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="3" type="1" style="margin:0pt; padding-left:0pt; list-style: none;">
        <li style='margin-left: 82px; text-indent: -39pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>1.3.3 Customer expressly warrants that it shall not use the Services for any unlawful, immoral, or any other purpose contrary to public policy, or that will impair the purposes of the Services or will impugn or put PurpleBug into disrepute.</li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-size: 16px; font-family: "Gotham", courier;'><strong>&nbsp;</strong></span></p>
    <ol start="4" type="1" style="margin:0pt; padding-left:0pt; list-style: none;">
        <li style='margin-left: 39.6pt; text-indent: -21.6pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px; font-weight: bold;'><span style="width: 3.94pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;</span>1.4 CONFIDENTIALITY</li>
    </ol>
    <p style="margin-top:0pt; margin-left:39.6pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-size: 16px; font-family: "Gotham", courier;'><strong>&nbsp;</strong></span></p>
    <ol type="1" style="margin:0pt; padding-left:0pt; list-style: none;">
        <li style='margin-left: 62pt; text-indent: -36pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>1.4.1 A party receiving information from another shall keep the same and all other data&nbsp; strictly confidential. This includes, but is not limited to, technical information, technologies, know-how, techniques, processes, and other information provided by PurpleBug to the Customer while this Policy is in place, or during the course of negotiations and performance of the same or any other agreement between the parties (collectively, &ldquo;Confidential Information&quot;).</li>
    </ol>
    <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="2" type="1" style="margin:0pt; padding-left:0pt; list-style: none;">
        <li style='margin-left: 62pt; text-indent: -40pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>1.4.2 Confidential Information shall not be disclosed, directly or indirectly, to any third party, or to any of its employees, agents, officers and representatives. The receiving party must employ reasonable measures to protect this the Confidential Information, treating it with the same level of care as they do their own similar Confidential Information. Unauthorized use, disclosure, or attempts to access or alter the information should be prevented.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:61.2pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="5" type="1" style="margin:0pt; padding-left:0pt; list-style: none;">
        <li style='margin-left: 39.6pt; text-indent: -21.6pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px; font-weight: bold;'><span style="width: 3.94pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;</span>1.5 DISCONTINUANCE OF SERVICE</li>
    </ol>
    <p style="margin-top:0pt; margin-left:39.6pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-size: 16px; font-family: "Gotham", courier;'><strong>&nbsp;</strong></span></p>
    <ol type="1" style="margin:0pt; padding-left:0pt; list-style: none;">
        <li style='margin-left: 62pt; text-indent: -36pt; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>1.5.1 PurpleBug shall have the option to pre-terminate or suspend the Services to Customer, without prejudice to other remedies available to PurpleBug, upon the occurrence of any of the following events:</li>
    </ol>
    <p style="margin-top:0pt; margin-left:61.2pt; margin-bottom:0pt; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol type="1" style="margin:0pt; padding-left:0pt; list-style: none;">
        <li style='margin-left: 102pt; text-indent: -53pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 18.68pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>1.5.1.1 Customer&rsquo;s non-payment of overdue accounts involving other existing PurpleBug Services which, based on PurpleBug &apos;s records, are maintained or owned by or kept under the same Customer&apos;s name. Aside from the outstanding charges of the Customer due to PurpleBug, applicable pre- termination charges shall be imposed.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:108pt; margin-bottom:0pt; text-indent:-54pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="2" type="1" style="margin:0pt; padding-left:0pt; list-style: none;">
        <li style='margin-left: 102pt; text-indent: -55pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 18.68pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>1.5.1.2 Customer becomes subject of any voluntary or involuntary proceedings under bankruptcy or insolvency laws, or has a substantial change in its ownership as applicable; a substantial change in ownership occurs when more than fifty-percent of shareholdings, or equivalent ownership representation in Customer, has changed after PurpleBug and Customer enter into the relevant agreement.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:102pt; margin-bottom:0pt; text-indent:-53pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="3" type="1" style="margin:0pt; padding-left:0pt; list-style: none;">
        <li style='margin-left: 102pt; text-indent: -55pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 18.68pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>1.5.1.3 Breach by Customer of any obligation under this Policy or other agreements between Customer and PurpleBug, including, without limitation, violation of the rules and regulations of PurpleBug, or breach of Customers representations and warranties.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:108pt; margin-bottom:0pt; text-indent:-54pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="4" type="1" style="margin:0pt; padding-left:0pt; list-style: none;">
        <li style='margin-left: 102pt; text-indent: -53pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 18.68pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>1.5.1.4 Non-payment of Service Fees for a period of more than <a name="_cmntref1"><span style=" ">Fifteen (15) days</span>&nbsp;</a><a href="#_cmnt1" style="text-decoration:none;"></a>from the due date.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:108pt; margin-bottom:0pt; text-indent:-54pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="5" type="1" style="margin:0pt; padding-left:0pt; list-style: none;">
        <li style='margin-left: 102pt; text-indent: -53pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 18.68pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>1.5.1.5 Reasonable grounds to believe that Customer is using the Services for immoral or illegal purposes, or is allowing a non-authorized third party to use the Services for whatever reason.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:108pt; margin-bottom:0pt; text-indent:-54pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="6" type="1" style="margin:0pt; padding-left:0pt; list-style: none;">
        <li style='margin-left: 102pt; text-indent: -53pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 18.68pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a name="_cmntref2">1.5.1.6 In addition to having the Service temporarily/permanently disconnected, there shall be a <span style=" ">three percent (3%) interest penalty per month for accounts with 30 days past due.</span></a><a href="#_cmnt2" style="text-decoration:none;"><span style=" "></span></a></li>
    </ol>
    <p style="margin-top:0pt; margin-left:86.4pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="6" type="1" style="margin:0pt; padding-left:0pt; list-style: none;">
        <li style='margin-left: 39.6pt; text-indent: -21.6pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px; font-weight: bold;'><span style="width: 3.94pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;</span>1.6 RECONNECTION OF SERVICE</li>
    </ol>
    <p style="margin-top:0pt; margin-left:39.6pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-size: 16px; font-family: "Gotham", courier;'><strong>&nbsp;</strong></span></p>
    <ol type="1" class="awlist27" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 72pt; text-indent: -35pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>PurpleBug shall charge a set of reconnection fee amounting to <span style=""><span style="text-decoration: underline;">XXX.XX</span> (PhP <span style="text-decoration: underline;">XXX.XX</span> )</span>. Further, all outstanding amounts due from the Customer shall all be demanded prior to reconnection. PurpleBug shall effect reconnection within <span style="text-decoration: underline;">XX</span> working days after all outstanding obligations of Customer are settled in full.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:61.2pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="7" type="1" style="margin:0pt; padding-left:0pt; list-style: none;">
        <li style='margin-left: 39.6pt; text-indent: -21.6pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px; font-weight: bold;'><span style="width: 3.94pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;</span>1.7 LIMITATIONS ON LIABILITY</li>
    </ol>
    <p style="margin-top:0pt; margin-left:39.6pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol type="1" class="awlist29" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 72pt; text-indent: -32pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>The Customer hereby holds PurpleBug free and harmless from any claim, liability, or damage to Customer or third parties of whatever kind or character, whether direct or indirect, or however else described in law or as generally understood, including but not limited to loss of opportunity, anticipated profits, or other economic gain, by reason of a power outage, interruption, suspension, termination or disconnection of the Services of PurpleBug as provided herein, whether or not PurpleBug was made aware of the possibility of such loss or damage or should have been aware of the possibility.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:61.2pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-size: 16px; font-family: "Gotham", courier;'><strong>&nbsp;</strong></span></p>
    <ol start="8" type="1" style="margin:0pt; padding-left:0pt; list-style: none;">
        <li style='margin-left: 39.6pt; text-indent: -21.6pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px; font-weight: bold;'><span style="width: 3.94pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;</span>1.8 MISCELLANEOUS</li>
    </ol>
    <p style="margin-top:0pt; margin-left:39.6pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-size: 16px; font-family: "Gotham", courier;'><strong>&nbsp;</strong></span></p>
    <ol type="1" class="awlist31" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 72pt; text-indent: -36pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>This Policy shall be governed by and interpreted according to the laws of the Republic of the Philippines, and the Customer and PurpleBug hereby submit to the exclusive jurisdiction of the courts of the <a name="_cmntref3"><span style=" ">City of Makati,</span> Philippines</a>, to the exclusion of all other courts, for any dispute of whatsoever nature arising from, or related to, this Policy or any further agreement that the parties may enter into.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="2" type="1" class="awlist32" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 72pt; text-indent: -36pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>If any one or more of the provisions contained in this Policy shall be invalid, illegal, or unenforceable in any respect under any applicable law, then the validity, legality, and enforceability of the remaining provisions contained herein or therein shall not in any way be affected or impaired and shall remain in full force and effect. Where necessary, PurpleBug and Customer shall enter into further agreements to correct and put into effect as best as possible, within the bounds of law or any relevant court of government decision, provisions that may be deemed invalid, illegal, or unenforceable.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:61.2pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-size: 16px; font-family: "Gotham", courier;'><strong>&nbsp;</strong></span></p>
    <ol start="2" type="1" class="awlist33" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 16pt; text-indent: -16pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px; font-weight: bold;'><span style="width: 9.17pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><u>DATA PROCESSING POLICY</u></li>
    </ol>
    <p style="margin-top:0pt; margin-left:16pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-size: 16px; font-family: "Gotham", courier;'><strong>&nbsp;</strong></span></p>
    <ol type="1" class="awlist34" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 39.6pt; text-indent: -21.6pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px; font-weight: bold;'><span style="width: 3.94pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;</span><a name="_cmntref4">INFORMATION WE COLLECT</a><a href="#_cmnt4" style="text-decoration:none;"><span style="font-weight:normal;"></span></a></li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol type="1" class="awlist35" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 61.2pt; text-indent: -25.2pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px; list-style-position: inside;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Information you provide us directly such as, but not limited to:</li>
    </ol>
    <p style="margin-top:0pt; margin-left:61.2pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol type="1" class="awlist36" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 108pt; text-indent: -36pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 0.68pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;</span> Your full name, personal photographs, gender, age, birthdate, email address, mobile number, employment, geolocation, in-platform activity, device information, and computer internet protocol address (&ldquo;I.P.&rdquo;) addressAnalytics information.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:61.2pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="2" type="1" class="awlist37" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 61.2pt; text-indent: -25.2pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px; list-style-position: inside;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Cookies and similar technologies.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:61.2pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="3" type="1" class="awlist38" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 61.2pt; text-indent: -25.2pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px; list-style-position: inside;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Log file information.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:61.2pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="4" type="1" class="awlist39" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 61.2pt; text-indent: -25.2pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px; list-style-position: inside;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Device identifiers.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:61.2pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-size: 16px; font-family: "Gotham", courier;'><strong>&nbsp;</strong></span></p>
    <ol start="2" type="1" class="awlist40" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 39.6pt; text-indent: -21.6pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px; font-weight: bold;'><span style="width: 3.94pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;</span><a name="_cmntref5">WHY WE COLLECT DATA&nbsp;</a><a href="#_cmnt5" style="text-decoration:none;"><span style="font-weight:normal;"></span></a></li>
    </ol>
    <p style="margin-top:0pt; margin-left:39.6pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-size: 16px; font-family: "Gotham", courier;'><strong>&nbsp;</strong></span></p>
    <ol type="1" class="awlist41" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 72pt; text-indent: -36pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>To provide, improve, and monitor the effectiveness of our Services.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="2" type="1" class="awlist42" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 72pt; text-indent: -36pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>To enhance the design of our platform&rsquo;s user interface and improve our customer support processes.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="3" type="1" class="awlist43" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 72pt; text-indent: -36pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>To comply with industry-standard know your customer (KYC) standards.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-36pt; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="4" type="1" class="awlist44" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 72pt; text-indent: -36pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>To monitor the safety of our virtual community and prevent illegal activities.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="5" type="1" class="awlist45" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 76pt; text-indent: -40pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>To ensure compliance with legal requirements, rules, regulations, and/or orders of duly constituted authorities, agents, and/or institutions of the Philippine national government, local governments, and other relevant government agencies.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="6" type="1" class="awlist46" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 72pt; text-indent: -36pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>To monitor the total number of visitors, traffic, demographic patterns, and other statistical data.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-36pt; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="7" type="1" class="awlist47" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 72pt; text-indent: -36pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>For general marketing or commercial purposes, provided the same is consented to by the Data Subject.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:61.2pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="3" type="1" class="awlist48" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 39.6pt; text-indent: -21.6pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px; font-weight: bold;'><span style="width: 3.94pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;</span>DEFINITIONS</li>
    </ol>
    <p style="margin-top:0pt; margin-left:39.6pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-size: 16px; font-family: "Gotham", courier;'><strong>&nbsp;</strong></span></p>
    <ol type="1" class="awlist49" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 61.2pt; text-indent: -25.2pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px; list-style-position: inside;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>The following terms shall be defined as follows:</li>
    </ol>
    <p style="margin-top:0pt; margin-left:61.2pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol type="1" class="awlist50" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 108pt; text-indent: -39pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 0.68pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;</span> Customer Personal Data refers to Personal Data that the Customer discloses to PurpleBug, or which PurpleBug processes or possesses on behalf of the Customer, or PurpleBug otherwise obtains as a result of, or in connection with, the Services.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:108pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="2" type="1" class="awlist51" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 106pt; text-indent: -36pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 0.68pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;</span>Commission refers to the National Privacy Commission of the Philippines or the NPC;</li>
    </ol>
    <p style="margin-top:0pt; margin-left:108pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="3" type="1" class="awlist52" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 109pt; text-indent: -39pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 0.68pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;</span>Consent refers to any freely given, specific, informed indication of will, whereby the Data Subject agrees to the collection and processing of his or her personal, sensitive personal, or privileged information. Consent shall be evidenced by written, electronic, or recorded means. It may also be given on behalf of a Data Subject by a lawful representative or an agent specifically authorized by the Data Subject to do so;</li>
    </ol>
    <p style="margin-top:0pt; margin-left:108pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="4" type="1" class="awlist53" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 108pt; text-indent: -39pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 0.68pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;</span>Data Privacy Act of 2012 (&ldquo;DPA&rdquo;) refers collectively to Republic Act No. 10173 of the Philippines and its corresponding Implementing Rules and Regulations (&ldquo;IRR&rdquo;), issuances of the NPC, and amendments thereto;</li>
    </ol>
    <p style="margin-top:0pt; margin-left:108pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="5" type="1" class="awlist54" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 108pt; text-indent: -39pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 0.68pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;</span>Data Protection Officer refers to an individual designated by each Party as such, in accord with the DPA, who is accountable for compliance with the DPA;</li>
    </ol>
    <p style="margin-top:0pt; margin-left:108pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="6" type="1" class="awlist55" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 108pt; text-indent: -36pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 0.68pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;</span>Data Subject refers to an individual whose personal, sensitive personal, or privileged information is processed;</li>
    </ol>
    <p style="margin-top:0pt; margin-left:108pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="7" type="1" class="awlist56" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 108pt; text-indent: -36pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 0.68pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;</span>Personal Data refers to either of the following:</li>
    </ol>
    <p style="margin-top:0pt; margin-left:86.4pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol type="1" class="awlist57" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 180pt; text-indent: -72pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 27.85pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Personal Information refers to: any information, whether recorded in a material form or not, from which the identity of an individual is apparent or can be reasonably and directly ascertained by the entity holding the information, or when put together with other information would directly and certainly identify an individual.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:180pt; margin-bottom:0pt; text-indent:-72pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="2" type="1" class="awlist58" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 180pt; text-indent: -72pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 27.85pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Sensitive Personal Information refers to: personal information about an individual&apos;s race, ethnic origin, marital status, age, color, or religious, philosophical or political affiliations; it includes information about an individual&apos;s health, education, the genetic or sexual life of a person, or to any proceeding for any offense committed or alleged to have been committed by such individual, the disposal of such proceedings, or the sentence of any court in such proceedings; information issued by government agencies peculiar to an individual which includes, but is not limited to, social security numbers, previous or current health records, licenses or its denials, suspension or revocation, and tax returns; and specifically established by an executive order or an act of Congress to be kept classified.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:180pt; margin-bottom:0pt; text-indent:-72pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="3" type="1" class="awlist59" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 180pt; text-indent: -72pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 27.85pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Privileged information is that which law or the Rules of Court deem to constitute privileged information.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:111.6pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="8" type="1" class="awlist60" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 144pt; text-indent: -74pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 36.68pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Personal Data Breach refers to a breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to, personal data transmitted, stored, or otherwise processed.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:144pt; margin-bottom:0pt; text-indent:-72pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="9" type="1" class="awlist61" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 144pt; text-indent: -74pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 36.68pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Personal Information Controller (&ldquo;PIC&rdquo;) refers to the party who controls the processing of Personal Data, or instructs another to process Personal Data on its behalf. There is control if the party decides on what information is collected, or the purpose or extent of its processing. For purposes of this Data Processing Agreement, the Customer shall be the PIC.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:144pt; margin-bottom:0pt; text-indent:-72pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="10" type="1" class="awlist62" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 144pt; text-indent: -72pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 30.78pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Personal Information Processor (&ldquo;PIP&rdquo;) refers to any natural or juridical person or any other body to whom a Personal Information Controller may outsource or instruct the processing of Personal Data pertaining to a Data Subject. In general and insofar as the Services are concerned, a Customer is the PIC while PurpleBug shall be the PIP.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:144pt; margin-bottom:0pt; text-indent:-72pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="11" type="1" class="awlist63" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 144pt; text-indent: -69pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 30.78pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Personnel shall refer to the directors, employees, agents, consultants, successors, and assigns, or otherwise acting under the authority of PurpleBug;</li>
    </ol>
    <p style="margin-top:0pt; margin-left:144pt; margin-bottom:0pt; text-indent:-72pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="12" type="1" class="awlist64" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 144pt; text-indent: -72pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 30.78pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Processing refers to any operation or any set of operations performed upon Personal Data including, but not limited to, the collection, recording, organization, storage, updating or modification, retrieval, consultation, use, consolidation, blocking, erasure or destruction of data. Processing may be performed through automated means, or manual processing, if the Personal Data is contained or is intended to be contained in a filing system.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:144pt; margin-bottom:0pt; text-indent:-72pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="13" type="1" class="awlist65" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 144pt; text-indent: -72pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 30.78pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Security Incident refers to an event or occurrence that affects or tends to affect data protection, or may compromise the availability, integrity and confidentiality of personal data. It includes incidents that would have result to a personal data breach, if not for safeguards that have been put in place.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:86.4pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="4" type="1" class="awlist66" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 39.6pt; text-indent: -21.6pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px; font-weight: bold;'><span style="width: 3.94pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;</span>RESPONSIBILITIES OF PARTIES</li>
    </ol>
    <p style="margin-top:0pt; margin-left:39.6pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol type="1" class="awlist67" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 61.2pt; text-indent: -25.2pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px; list-style-position: inside;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>The responsibilities of the Customer as the PIC are as follows:</li>
    </ol>
    <p style="margin-top:0pt; margin-left:16pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol type="1" class="awlist68" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 108pt; text-indent: -54pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 18.68pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>As a PIC of the Personal Data in their original possession, the Customer shall warrant and be responsible for (a) ensuring it collects the Personal Data lawfully and in accordance with the requirements of the DPA;&nbsp; (b) obtaining the necessary prior consent of the Data Subject over the collection of their Personal Data; (c) apprising the Data Subject of the nature, purpose, and extent of the processing of his or her Personal Data, including the identity of PIC; and (d) his or her rights as a Data Subject, and how these rights can be exercised.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:108pt; margin-bottom:0pt; text-indent:-54pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="2" type="1" class="awlist69" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 108pt; text-indent: -58pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 18.68pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>The Customer as PIC shall be responsible for the quality, accuracy, and authenticity of the Personal Data being shared. The DPO of Customer shall take into account existing controls in the collection and processing of Personal Data that will be shared in order to give reasonable assurance that it is accurate and up to date. Adequate care must be undertaken specifically for Sensitive Personal Information.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:86.4pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="2" type="1" class="awlist70" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 61.2pt; text-indent: -25.2pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px; list-style-position: inside;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>The responsibilities of PurpleBug as the PIP are as follows:</li>
    </ol>
    <p style="margin-top:0pt; margin-left:86.4pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol type="1" class="awlist71" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 108pt; text-indent: -54pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 18.68pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>It shall not share Customer Personal Data with any third party without the prior written permission and instruction of the Customer.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:108pt; margin-bottom:0pt; text-indent:-54pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="2" type="1" class="awlist72" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 112pt; text-indent: -59pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 18.68pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>It shall use or process Customer Personal Data only for the purpose of fulfilling its obligations in the delivery of the Services. PurpleBug shall not otherwise use or process Customer Personal Data unless in accordance with Customer&rsquo;s documented instructions, provided that instructions previously given may be deemed applicable and continuing if not in the meantime withdrawn by Customer.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:108pt; margin-bottom:0pt; text-indent:-54pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="3" type="1" class="awlist73" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 108pt; text-indent: -54pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 18.68pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>It shall segregate Customer Personal Data from its own and its other clients&rsquo; data.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:108pt; margin-bottom:0pt; text-indent:-54pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="4" type="1" class="awlist74" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 108pt; text-indent: -54pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 18.68pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>PurpleBug may transfer Customer Personal Data to a jurisdiction outside the Philippines with the prior written consent of the Customer.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:108pt; margin-bottom:0pt; text-indent:-54pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="5" type="1" class="awlist75" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 115pt; text-indent: -61pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 18.68pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>In case of any judicial order, governmental action, or legal obligation requiring PurpleBug to disclose Customer Personal Data, PurpleBug shall immediately inform the Customer thereof, without prejudice to PurpleBug in the meantime complying with the said judicial order, governmental action, or legal obligation if periods imposed by law so require. At Customer&rsquo;s cost and to the extent allowed by law, PurpleBug shall support and cooperate in the intervention of the Customer in contesting the judicial order, governmental action, or legal obligation, or minimizing the scope of the disclosure.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:111.6pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="5" type="1" class="awlist76" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 39.6pt; text-indent: -21.6pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px; font-weight: bold;'><span style="width: 3.94pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;</span>ORGANIZATIONAL, TECHNICAL, AND PHYSICAL SECURITY MEASURES</li>
    </ol>
    <p style="margin-top:0pt; margin-left:39.6pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-size: 16px; font-family: "Gotham", courier;'><strong>&nbsp;</strong></span></p>
    <ol type="1" class="awlist77" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 72pt; text-indent: -36pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Customer shall have in place appropriate organizational, technical, and physical security measures that protect Personal Data from Security Incidents and Personal Data Breaches.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="2" type="1" class="awlist78" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 78pt; text-indent: -42pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Customer shall ensure that Personal Data is backed up on a regular basis, and that any backup is subject to security measures as necessary to protect the confidentiality, integrity, and availability of Personal Data.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="3" type="1" class="awlist79" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 78pt; text-indent: -41pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>PurpleBug may restrict the use of any access granted to the Customer for the Services, only for such purposes as authorized under this Policy or any further agreement between the Parties, to prevent misuse and abuse of such access.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="4" type="1" class="awlist80" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 72pt; text-indent: -36pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Customer shall ensure that its Personnel will not perform any duties incompatible with the fulfillment of his/her roles and responsibilities.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="5" type="1" class="awlist81" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 72pt; text-indent: -36pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Customer shall not perform any means to circumvent the security mechanisms in place or create vulnerabilities in the Services.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="6" type="1" class="awlist82" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 79pt; text-indent: -42pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Customer shall be responsible for any activities performed by its Personnel on the Customer&rsquo;s data, systems, and applications and develop a process for reviewing work performed by its Personnel to determine the appropriateness of activities performed and checks for suspicious transactions if any.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="7" type="1" class="awlist83" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 72pt; text-indent: -36pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Customer shall comply with all PurpleBug requirements for the Services.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:61.2pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="6" type="1" class="awlist84" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 39.6pt; text-indent: -21.6pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px; font-weight: bold;'><span style="width: 3.94pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;</span>PERSONNEL</li>
    </ol>
    <p style="margin-top:0pt; margin-left:39.6pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol type="1" class="awlist85" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 72pt; text-indent: -36pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Customer shall take steps to ensure that any person acting under its authority, and who has access to Customer Personal Data, does not process them except for purposes of this Policy or as required by law.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="2" type="1" class="awlist86" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 78pt; text-indent: -41pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Each Party shall disclose the Customer Personal Data only to its Personnel who need to have access to it only for the purpose set out in this Policy or any other relevant agreement between the Parties.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="3" type="1" class="awlist87" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 78pt; text-indent: -41pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Customer shall ensure that its Personnel engaged in the Processing of Customer Personal Data are informed of and understand the confidential nature of the Customer Personal Data, are subject to obligations of confidentiality, and that such obligations survive the termination of that Personnel&rsquo;s engagement or relationship with Customer.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="4" type="1" class="awlist88" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 80pt; text-indent: -42pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Customer shall ensure the reliability of any of its Personnel who has access to Customer Personal Data, such as ensuring he or she has received appropriate training in data protection prior to their access or Processing of Customer Personal Data.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:61.2pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="7" type="1" class="awlist89" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 39.6pt; text-indent: -21.6pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px; font-weight: bold;'><span style="width: 3.94pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;</span>DATA SUBJECT ACCESS RIGHTS</li>
    </ol>
    <p style="margin-top:0pt; margin-left:39.6pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol type="1" class="awlist90" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 72pt; text-indent: -36pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>The Parties recognize that Data Subjects have express rights under the DPA and other applicable data privacy laws, which provide for the protection and confidentiality of their Personal Data.&nbsp; Data Subjects have a right to see what Personal Data is held about them, and to know why and how it is processed. Any inquiry or request of Personal Data by a Data Subject can be made by submitting a written request to Customer. Where necessary and applicable, such as when Personal Data of Customer&nbsp; Customer shall furnish PurpleBug with the said request, together with Customer&rsquo;s own request..</li>
    </ol>
    <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="2" type="1" class="awlist91" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 77pt; text-indent: -39pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>The Customer, as a Personal Information Controller, has an obligation to respond to any request or complaint by a Data Subject. PurpleBug, as a Personal Information Processor, shall:</li>
    </ol>
    <p style="margin-top:0pt; margin-left:61.2pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol type="1" class="awlist92" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 108pt; text-indent: -54pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 18.68pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Immediately notify the Customer if it receives a request or complaint from a Data Subject as regards Personal Data.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:108pt; margin-bottom:0pt; text-indent:-54pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="2" type="1" class="awlist93" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 113pt; text-indent: -59pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 18.68pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Ensure that it does not respond to that request except on the documented instructions of the Customer, or as required under the DPA, reserving its right to inform the Data Subject that Purplebug is only a PIP and that Customer is the PIC, or that Personal Data is not held by PurpleBug, as applicable.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:108pt; margin-bottom:0pt; text-indent:-54pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="3" type="1" class="awlist94" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 113pt; text-indent: -59pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 18.68pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>The Customer, with the assistance and cooperation of PurpleBug as necessary, shall ensure the request or complaint of any Data Subject is duly acted upon within thirty (30) days from receipt of notice.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:108pt; margin-bottom:0pt; text-indent:-54pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="4" type="1" class="awlist95" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 114pt; text-indent: -59pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 18.68pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Customer shall have an established procedure in upholding and enforcing Data Subject rights. PurpleBug shall be indemnified for any cost or liability paid to any Data Subject, who suffers any damage due to inaccurate, incomplete, outdated, false, and unlawfully obtained Personal Data and/or unauthorized use of Personal Data by Customer.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:86.4pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="8" type="1" class="awlist96" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 39.6pt; text-indent: -21.6pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px; font-weight: bold;'><span style="width: 3.94pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;</span>PERSONAL DATA BREACH NOTIFICATION</li>
    </ol>
    <p style="margin-top:0pt; margin-left:39.6pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol type="1" class="awlist97" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 72pt; text-indent: -36pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>The Parties shall immediately notify the other if it becomes aware of any potential or actual security or data privacy incident involving Customer Personal Data. Customer shall implement policies and procedures for the guidance of its Personnel in the event of a Personal Data Breach.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:61.2pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="9" type="1" class="awlist98" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 39.6pt; text-indent: -21.6pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px; font-weight: bold;'><span style="width: 3.94pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;</span>AUDIT</li>
    </ol>
    <p style="margin-top:0pt; margin-left:39.6pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol type="1" class="awlist99" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 72pt; text-indent: -36pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>PurpleBug shall have the right to audit and inspect the organizational, technical, and physical security measures implemented by Customer, only insofar as the same is necessary for this Policy or any other further agreement between the Parties.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="2" type="1" class="awlist100" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 72pt; text-indent: -36pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>In the course of such audit, PurpleBug may conduct the following measures, including but not limited to:</li>
    </ol>
    <p style="margin-top:0pt; margin-left:61.2pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol type="1" class="awlist101" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 144pt; text-indent: -36pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 0.68pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;</span> Obtaining any and all relevant information from Customer necessary to demonstrate its compliance with this Policy.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:144pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="2" type="1" class="awlist102" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 154pt; text-indent: -47pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 0.68pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;</span> Requesting Customer to submit an existing attestation or certificate by an independent professional expert on its compliance with the DPA and the security requirements therein.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:144pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="3" type="1" class="awlist103" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 154pt; text-indent: -47pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 0.68pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;</span> Conducting an on-site inspection of the business operations of Customer or have the same conducted by a qualified third party auditor or assessor, which shall not be an existing independent consultant of Customer. The on-site inspection shall be conducted during regular business hours and with reasonable and timely advance notice to Customer.&nbsp;</li>
    </ol>
    <p style="margin-top:0pt; margin-left:86.4pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="3" type="1" class="awlist104" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 79pt; text-indent: -41pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Customer shall, after written request and within a reasonable period of time, submit to the PurpleBug any and all information, documentation, and/or other means of factual proof necessary for the conduct of an audit.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="4" type="1" class="awlist105" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 79pt; text-indent: -41pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.51pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>PurpleBug, upon its discretion and in good faith, may extend its review, if needed, and Customer is expected to heed the request until PurpleBug is satisfied with the conduct of the review.</li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:0pt;"><span style='font-size: 16px; font-family: "Gotham", courier;'>&nbsp;</span></p>
    <p style="margin-top:0pt; margin-left:61.2pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-size: 16px; font-family: "Gotham", courier;'><strong>&nbsp;</strong></span></p>
    <ol start="10" type="1" class="awlist106" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 39.6pt; text-indent: -21.6pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px; font-weight: bold; list-style-position: inside;'><span style="width: 30.44pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>DATA RETENTION PERIOD</li>
    </ol>
    <p style="margin-top:0pt; margin-left:39.6pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol type="1" class="awlist107" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 72pt; text-indent: -36pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 3.61pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;</span>PurpleBug will only process and retain Customer Personal Data for the duration of this Policy or any relevant agreement between the Parties. Customer Personal Data may be deleted, returned, or retained upon expiry thereof, subject to what Parties may agree on. Sans any agreement, PurpleBug may delete, without liability, Customer Personal Data.&nbsp; PurpleBug retains the discretion to approve or decline such request.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="2" type="1" class="awlist108" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 72pt; text-indent: -36pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 3.61pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;</span>If a complaint or notice is received about the accuracy of Customer Personal Data which affects Personal and/or Sensitive Personal Information shared with PurpleBug, a revised Customer Personal Data will be communicated to PurpleBug.&nbsp; PurpleBug disclaims any liability whatsoever for Personal Data provided to it by Customer.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:61.2pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="11" type="1" class="awlist109" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 39.6pt; text-indent: -21.6pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px; font-weight: bold; list-style-position: inside;'><span style="width: 30.44pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>RETURN OR DELETION OF CUSTOMER PERSONAL DATA</li>
    </ol>
    <p style="margin-top:0pt; margin-left:39.6pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol type="1" class="awlist110" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 108pt; text-indent: -48pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 12.78pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Unless return, retention, or extended processing is requested or required by the DPA or other regulation, PurpleBug may, upon expiry of this Policy or any further relevant agreement, destroy all copies of Customer Personal Data and any other property, information, and documents provided by the Customer.&nbsp; For print-outs or other tangible formats, the document or object will be shredded or otherwise physically destroyed. For data in electronic form, the document will be permanently deleted, wiped, overwritten, or otherwise made irretrievable.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:86.4pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <p style="margin-top:0pt; margin-left:86.4pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="2" type="1" class="awlist111" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 92pt; text-indent: -34pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 3.61pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;</span>In cases where the Customer receives a request from its customers for the deletion of Customer Personal Data under the custody of PurpleBug, the Customer shall forward this request to PurpleBug, which request shall be acted upon within a reasonable period.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:61.2pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol type="1" class="awlist112" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 144pt; text-indent: -69pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 30.78pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>The Customer shall have the sole responsibility of determining the validity or genuineness of the request for deletion and shall only forward valid requests for deletion to PurpleBug. PurpleBug shall not be liable in any event for deletion of data upon a request sent through Customer, regardless of the validity or genuineness of the request.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:86.4pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="12" type="1" class="awlist113" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 39.6pt; text-indent: -21.6pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px; font-weight: bold; list-style-position: inside;'><span style="width: 30.44pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>LIABILITY</li>
    </ol>
    <p style="margin-top:0pt; margin-left:39.6pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol type="1" class="awlist114" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 72pt; text-indent: -36pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 3.61pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;</span>Customer shall be liable for any damage or loss that results from its failure or refusal to perform any obligation under this Policy, or its breach of the warranties and representations made herein.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="2" type="1" class="awlist115" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 74pt; text-indent: -38pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 3.61pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;</span>In case the Security Incident or Personal Data Breach of is material and substantial, and such will cause PurpleBug irreparable injury for which it would have no adequate remedy at law, and for which there is an urgent and permanent necessity to prevent serious damage, the Customer shall be entitled to immediately seek an injunctive relief prohibiting any violation of this Policy, in addition to any other rights and remedies available to it.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="3" type="1" class="awlist116" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 76pt; text-indent: -38pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 3.61pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;</span>Customer shall defend, indemnify, and hold PurpleBug, its affiliates, and their respective officers, directors, stockholders, employees, and agents, harmless from and against any and all claims, suits, causes of action, liability, loss, costs, and damages, including attorney&rsquo;s fees and costs of litigation, in connection with or as a result of any third party claim arising from the Security Incident or Personal Data Breach of Customer.</li>
    </ol>
    <p style="margin-top:0pt; margin-left:61.2pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="3" type="1" class="awlist117" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 16pt; text-indent: -16pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px;'><span style="width: 9.17pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><strong><u>COOKIE POLICY</u></strong></li>
    </ol>
    <p style="margin-top:0pt; margin-left:16pt; margin-bottom:0pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol type="1" class="awlist118" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 72pt; text-indent: -38pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px; font-weight: bold;'><span style="width: 18.34pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="font-weight: normal;">Cookies are used to customize, enhance, and optimize your browsing, use, and overall experience in our Services. Cookies stored in the Services provide various functions and facilitate certain features, such as storage of certain information that can be retrieved by the web server which can Service utilization more comfortable and useful for all users.</span></li>
    </ol>
    <p style="margin-top:0pt; margin-left:72pt; margin-bottom:0pt; text-indent:-36pt; text-align:justify; font-size:12pt;"><span style='font-family: "Gotham", courier; font-size: 16px;'>&nbsp;</span></p>
    <ol start="2" type="1" class="awlist119" style="margin:0pt; padding-left:0pt;">
        <li style='margin-left: 75pt; text-indent: -41pt; text-align: justify; font-family: "Gotham", courier; font-size: 16px; font-weight: bold;'><span style="width: 18.34pt; font-style: normal; font-variant: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; line-height: normal; display: inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="font-weight: normal;">Customer can decide on how its device will accept, limit or block cookies by configuring Customer&rsquo;s own preferences or choices through its web browser settings. In addition, Customer can also configure multiple devices to notify it when a cookie is being placed and thereafter decide whether Customer will accept the cookie. Customer may undertake other measures as it may desire as regards Cookies.&nbsp;</span></li>
    </ol>
</div>
</template>
<style type="text/css">
.awlist1 {
    list-style: none;
    counter-reset: awlistcounter2_0
}

.awlist1>li:before {
    content: counter(awlistcounter2_0) '.';
    counter-increment: awlistcounter2_0
}

.awlist2 {
    list-style: none;
    counter-reset: awlistcounter2_1
}

.awlist2>li:before {
    content: '1.'counter(awlistcounter2_1) '.';
    counter-increment: awlistcounter2_1
}

.awlist3 {
    list-style: none;
    counter-reset: awlistcounter2_2
}

.awlist3>li:before {
    content: '1.1.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist4 {
    list-style: none;
    counter-reset: awlistcounter2_2 1
}

.awlist4>li:before {
    content: '1.1.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist5 {
    list-style: none;
    counter-reset: awlistcounter2_2 2
}

.awlist5>li:before {
    content: '1.1.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist6 {
    list-style: none;
    counter-reset: awlistcounter2_2 3
}

.awlist6>li:before {
    content: '1.1.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist7 {
    list-style: none;
    counter-reset: awlistcounter2_2 4
}

.awlist7>li:before {
    content: '1.1.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist8 {
    list-style: none;
    counter-reset: awlistcounter2_2 5
}

.awlist8>li:before {
    content: '1.1.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist9 {
    list-style: none;
    counter-reset: awlistcounter2_1 1
}

.awlist9>li:before {
    content: '1.'counter(awlistcounter2_1) '.';
    counter-increment: awlistcounter2_1
}

.awlist10 {
    list-style: none;
    counter-reset: awlistcounter2_2
}

.awlist10>li:before {
    content: '1.2.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist11 {
    list-style: none;
    counter-reset: awlistcounter2_1 2
}

.awlist11>li:before {
    content: '1.'counter(awlistcounter2_1) '.';
    counter-increment: awlistcounter2_1
}

.awlist12 {
    list-style: none;
    counter-reset: awlistcounter2_2
}

.awlist12>li:before {
    content: '1.3.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist13 {
    list-style: none;
    counter-reset: awlistcounter2_2 1
}

.awlist13>li:before {
    content: '1.3.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist14 {
    list-style: none;
    counter-reset: awlistcounter2_2 2
}

.awlist14>li:before {
    content: '1.3.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist15 {
    list-style: none;
    counter-reset: awlistcounter2_1 3
}

.awlist15>li:before {
    content: '1.'counter(awlistcounter2_1) '.';
    counter-increment: awlistcounter2_1
}

.awlist16 {
    list-style: none;
    counter-reset: awlistcounter2_2
}

.awlist16>li:before {
    content: '1.4.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist17 {
    list-style: none;
    counter-reset: awlistcounter2_2 1
}

.awlist17>li:before {
    content: '1.4.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist18 {
    list-style: none;
    counter-reset: awlistcounter2_1 4
}

.awlist18>li:before {
    content: '1.'counter(awlistcounter2_1) '.';
    counter-increment: awlistcounter2_1
}

.awlist19 {
    list-style: none;
    counter-reset: awlistcounter2_2
}

.awlist19>li:before {
    content: '1.5.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist20 {
    list-style: none;
    counter-reset: awlistcounter2_3
}

.awlist20>li:before {
    content: '1.5.1.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist21 {
    list-style: none;
    counter-reset: awlistcounter2_3 1
}

.awlist21>li:before {
    content: '1.5.1.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist22 {
    list-style: none;
    counter-reset: awlistcounter2_3 2
}

.awlist22>li:before {
    content: '1.5.1.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist23 {
    list-style: none;
    counter-reset: awlistcounter2_3 3
}

.awlist23>li:before {
    content: '1.5.1.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist24 {
    list-style: none;
    counter-reset: awlistcounter2_3 4
}

.awlist24>li:before {
    content: '1.5.1.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist25 {
    list-style: none;
    counter-reset: awlistcounter2_3 5
}

.awlist25>li:before {
    content: '1.5.1.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist26 {
    list-style: none;
    counter-reset: awlistcounter2_1 5
}

.awlist26>li:before {
    content: '1.'counter(awlistcounter2_1) '.';
    counter-increment: awlistcounter2_1
}

.awlist27 {
    list-style: none;
    counter-reset: awlistcounter2_2
}

.awlist27>li:before {
    content: '1.6.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist28 {
    list-style: none;
    counter-reset: awlistcounter2_1 6
}

.awlist28>li:before {
    content: '1.'counter(awlistcounter2_1) '.';
    counter-increment: awlistcounter2_1
}

.awlist29 {
    list-style: none;
    counter-reset: awlistcounter2_2
}

.awlist29>li:before {
    content: '1.7.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist30 {
    list-style: none;
    counter-reset: awlistcounter2_1 7
}

.awlist30>li:before {
    content: '1.'counter(awlistcounter2_1) '.';
    counter-increment: awlistcounter2_1
}

.awlist31 {
    list-style: none;
    counter-reset: awlistcounter2_2
}

.awlist31>li:before {
    content: '1.8.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist32 {
    list-style: none;
    counter-reset: awlistcounter2_2 1
}

.awlist32>li:before {
    content: '1.8.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist33 {
    list-style: none;
    counter-reset: awlistcounter2_0 1
}

.awlist33>li:before {
    content: counter(awlistcounter2_0) '.';
    counter-increment: awlistcounter2_0
}

.awlist34 {
    list-style: none;
    counter-reset: awlistcounter2_1
}

.awlist34>li:before {
    content: '2.'counter(awlistcounter2_1) '.';
    counter-increment: awlistcounter2_1
}

.awlist35 {
    list-style: none;
    counter-reset: awlistcounter2_2
}

.awlist35>li:before {
    content: '2.1.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist36 {
    list-style: none;
    counter-reset: awlistcounter2_3
}

.awlist36>li:before {
    content: '2.1.1.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist37 {
    list-style: none;
    counter-reset: awlistcounter2_2 1
}

.awlist37>li:before {
    content: '2.1.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist38 {
    list-style: none;
    counter-reset: awlistcounter2_2 2
}

.awlist38>li:before {
    content: '2.1.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist39 {
    list-style: none;
    counter-reset: awlistcounter2_2 3
}

.awlist39>li:before {
    content: '2.1.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist40 {
    list-style: none;
    counter-reset: awlistcounter2_1 1
}

.awlist40>li:before {
    content: '2.'counter(awlistcounter2_1) '.';
    counter-increment: awlistcounter2_1
}

.awlist41 {
    list-style: none;
    counter-reset: awlistcounter2_2
}

.awlist41>li:before {
    content: '2.2.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist42 {
    list-style: none;
    counter-reset: awlistcounter2_2 1
}

.awlist42>li:before {
    content: '2.2.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist43 {
    list-style: none;
    counter-reset: awlistcounter2_2 2
}

.awlist43>li:before {
    content: '2.2.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist44 {
    list-style: none;
    counter-reset: awlistcounter2_2 3
}

.awlist44>li:before {
    content: '2.2.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist45 {
    list-style: none;
    counter-reset: awlistcounter2_2 4
}

.awlist45>li:before {
    content: '2.2.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist46 {
    list-style: none;
    counter-reset: awlistcounter2_2 5
}

.awlist46>li:before {
    content: '2.2.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist47 {
    list-style: none;
    counter-reset: awlistcounter2_2 6
}

.awlist47>li:before {
    content: '2.2.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist48 {
    list-style: none;
    counter-reset: awlistcounter2_1 2
}

.awlist48>li:before {
    content: '2.'counter(awlistcounter2_1) '.';
    counter-increment: awlistcounter2_1
}

.awlist49 {
    list-style: none;
    counter-reset: awlistcounter2_2
}

.awlist49>li:before {
    content: '2.3.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist50 {
    list-style: none;
    counter-reset: awlistcounter2_3
}

.awlist50>li:before {
    content: '2.3.1.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist51 {
    list-style: none;
    counter-reset: awlistcounter2_3 1
}

.awlist51>li:before {
    content: '2.3.1.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist52 {
    list-style: none;
    counter-reset: awlistcounter2_3 2
}

.awlist52>li:before {
    content: '2.3.1.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist53 {
    list-style: none;
    counter-reset: awlistcounter2_3 3
}

.awlist53>li:before {
    content: '2.3.1.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist54 {
    list-style: none;
    counter-reset: awlistcounter2_3 4
}

.awlist54>li:before {
    content: '2.3.1.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist55 {
    list-style: none;
    counter-reset: awlistcounter2_3 5
}

.awlist55>li:before {
    content: '2.3.1.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist56 {
    list-style: none;
    counter-reset: awlistcounter2_3 6
}

.awlist56>li:before {
    content: '2.3.1.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist57 {
    list-style: none;
    counter-reset: awlistcounter2_4
}

.awlist57>li:before {
    content: '2.3.1.7.'counter(awlistcounter2_4) '.';
    counter-increment: awlistcounter2_4
}

.awlist58 {
    list-style: none;
    counter-reset: awlistcounter2_4 1
}

.awlist58>li:before {
    content: '2.3.1.7.'counter(awlistcounter2_4) '.';
    counter-increment: awlistcounter2_4
}

.awlist59 {
    list-style: none;
    counter-reset: awlistcounter2_4 2
}

.awlist59>li:before {
    content: '2.3.1.7.'counter(awlistcounter2_4) '.';
    counter-increment: awlistcounter2_4
}

.awlist60 {
    list-style: none;
    counter-reset: awlistcounter2_3 7
}

.awlist60>li:before {
    content: '2.3.1.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist61 {
    list-style: none;
    counter-reset: awlistcounter2_3 8
}

.awlist61>li:before {
    content: '2.3.1.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist62 {
    list-style: none;
    counter-reset: awlistcounter2_3 9
}

.awlist62>li:before {
    content: '2.3.1.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist63 {
    list-style: none;
    counter-reset: awlistcounter2_3 10
}

.awlist63>li:before {
    content: '2.3.1.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist64 {
    list-style: none;
    counter-reset: awlistcounter2_3 11
}

.awlist64>li:before {
    content: '2.3.1.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist65 {
    list-style: none;
    counter-reset: awlistcounter2_3 12
}

.awlist65>li:before {
    content: '2.3.1.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist66 {
    list-style: none;
    counter-reset: awlistcounter2_1 3
}

.awlist66>li:before {
    content: '2.'counter(awlistcounter2_1) '.';
    counter-increment: awlistcounter2_1
}

.awlist67 {
    list-style: none;
    counter-reset: awlistcounter2_2
}

.awlist67>li:before {
    content: '2.4.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist68 {
    list-style: none;
    counter-reset: awlistcounter2_3
}

.awlist68>li:before {
    content: '2.4.1.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist69 {
    list-style: none;
    counter-reset: awlistcounter2_3 1
}

.awlist69>li:before {
    content: '2.4.1.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist70 {
    list-style: none;
    counter-reset: awlistcounter2_2 1
}

.awlist70>li:before {
    content: '2.4.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist71 {
    list-style: none;
    counter-reset: awlistcounter2_3
}

.awlist71>li:before {
    content: '2.4.2.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist72 {
    list-style: none;
    counter-reset: awlistcounter2_3 1
}

.awlist72>li:before {
    content: '2.4.2.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist73 {
    list-style: none;
    counter-reset: awlistcounter2_3 2
}

.awlist73>li:before {
    content: '2.4.2.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist74 {
    list-style: none;
    counter-reset: awlistcounter2_3 3
}

.awlist74>li:before {
    content: '2.4.2.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist75 {
    list-style: none;
    counter-reset: awlistcounter2_3 4
}

.awlist75>li:before {
    content: '2.4.2.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist76 {
    list-style: none;
    counter-reset: awlistcounter2_1 4
}

.awlist76>li:before {
    content: '2.'counter(awlistcounter2_1) '.';
    counter-increment: awlistcounter2_1
}

.awlist77 {
    list-style: none;
    counter-reset: awlistcounter2_2
}

.awlist77>li:before {
    content: '2.5.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist78 {
    list-style: none;
    counter-reset: awlistcounter2_2 1
}

.awlist78>li:before {
    content: '2.5.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist79 {
    list-style: none;
    counter-reset: awlistcounter2_2 2
}

.awlist79>li:before {
    content: '2.5.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist80 {
    list-style: none;
    counter-reset: awlistcounter2_2 3
}

.awlist80>li:before {
    content: '2.5.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist81 {
    list-style: none;
    counter-reset: awlistcounter2_2 4
}

.awlist81>li:before {
    content: '2.5.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist82 {
    list-style: none;
    counter-reset: awlistcounter2_2 5
}

.awlist82>li:before {
    content: '2.5.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist83 {
    list-style: none;
    counter-reset: awlistcounter2_2 6
}

.awlist83>li:before {
    content: '2.5.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist84 {
    list-style: none;
    counter-reset: awlistcounter2_1 5
}

.awlist84>li:before {
    content: '2.'counter(awlistcounter2_1) '.';
    counter-increment: awlistcounter2_1
}

.awlist85 {
    list-style: none;
    counter-reset: awlistcounter2_2
}

.awlist85>li:before {
    content: '2.6.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist86 {
    list-style: none;
    counter-reset: awlistcounter2_2 1
}

.awlist86>li:before {
    content: '2.6.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist87 {
    list-style: none;
    counter-reset: awlistcounter2_2 2
}

.awlist87>li:before {
    content: '2.6.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist88 {
    list-style: none;
    counter-reset: awlistcounter2_2 3
}

.awlist88>li:before {
    content: '2.6.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist89 {
    list-style: none;
    counter-reset: awlistcounter2_1 6
}

.awlist89>li:before {
    content: '2.'counter(awlistcounter2_1) '.';
    counter-increment: awlistcounter2_1
}

.awlist90 {
    list-style: none;
    counter-reset: awlistcounter2_2
}

.awlist90>li:before {
    content: '2.7.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist91 {
    list-style: none;
    counter-reset: awlistcounter2_2 1
}

.awlist91>li:before {
    content: '2.7.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist92 {
    list-style: none;
    counter-reset: awlistcounter2_3
}

.awlist92>li:before {
    content: '2.7.2.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist93 {
    list-style: none;
    counter-reset: awlistcounter2_3 1
}

.awlist93>li:before {
    content: '2.7.2.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist94 {
    list-style: none;
    counter-reset: awlistcounter2_3 2
}

.awlist94>li:before {
    content: '2.7.2.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist95 {
    list-style: none;
    counter-reset: awlistcounter2_3 3
}

.awlist95>li:before {
    content: '2.7.2.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist96 {
    list-style: none;
    counter-reset: awlistcounter2_1 7
}

.awlist96>li:before {
    content: '2.'counter(awlistcounter2_1) '.';
    counter-increment: awlistcounter2_1
}

.awlist97 {
    list-style: none;
    counter-reset: awlistcounter2_2
}

.awlist97>li:before {
    content: '2.8.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist98 {
    list-style: none;
    counter-reset: awlistcounter2_1 8
}

.awlist98>li:before {
    content: '2.'counter(awlistcounter2_1) '.';
    counter-increment: awlistcounter2_1
}

.awlist99 {
    list-style: none;
    counter-reset: awlistcounter2_2
}

.awlist99>li:before {
    content: '2.9.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist100 {
    list-style: none;
    counter-reset: awlistcounter2_2 1
}

.awlist100>li:before {
    content: '2.9.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist101 {
    list-style: none;
    counter-reset: awlistcounter2_3
}

.awlist101>li:before {
    content: '2.9.2.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist102 {
    list-style: none;
    counter-reset: awlistcounter2_3 1
}

.awlist102>li:before {
    content: '2.9.2.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist103 {
    list-style: none;
    counter-reset: awlistcounter2_3 2
}

.awlist103>li:before {
    content: '2.9.2.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist104 {
    list-style: none;
    counter-reset: awlistcounter2_2 2
}

.awlist104>li:before {
    content: '2.9.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist105 {
    list-style: none;
    counter-reset: awlistcounter2_2 3
}

.awlist105>li:before {
    content: '2.9.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist106 {
    list-style: none;
    counter-reset: awlistcounter2_1 9
}

.awlist106>li:before {
    content: '2.'counter(awlistcounter2_1) '.';
    counter-increment: awlistcounter2_1
}

.awlist107 {
    list-style: none;
    counter-reset: awlistcounter2_2
}

.awlist107>li:before {
    content: '2.10.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist108 {
    list-style: none;
    counter-reset: awlistcounter2_2 1
}

.awlist108>li:before {
    content: '2.10.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist109 {
    list-style: none;
    counter-reset: awlistcounter2_1 10
}

.awlist109>li:before {
    content: '2.'counter(awlistcounter2_1) '.';
    counter-increment: awlistcounter2_1
}

.awlist110 {
    list-style: none;
    counter-reset: awlistcounter2_3
}

.awlist110>li:before {
    content: '2.11.1.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist111 {
    list-style: none;
    counter-reset: awlistcounter2_2 1
}

.awlist111>li:before {
    content: '2.11.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist112 {
    list-style: none;
    counter-reset: awlistcounter2_3
}

.awlist112>li:before {
    content: '2.11.2.'counter(awlistcounter2_3) '.';
    counter-increment: awlistcounter2_3
}

.awlist113 {
    list-style: none;
    counter-reset: awlistcounter2_1 11
}

.awlist113>li:before {
    content: '2.'counter(awlistcounter2_1) '.';
    counter-increment: awlistcounter2_1
}

.awlist114 {
    list-style: none;
    counter-reset: awlistcounter2_2
}

.awlist114>li:before {
    content: '2.12.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist115 {
    list-style: none;
    counter-reset: awlistcounter2_2 1
}

.awlist115>li:before {
    content: '2.12.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist116 {
    list-style: none;
    counter-reset: awlistcounter2_2 2
}

.awlist116>li:before {
    content: '2.12.'counter(awlistcounter2_2) '.';
    counter-increment: awlistcounter2_2
}

.awlist117 {
    list-style: none;
    counter-reset: awlistcounter2_0 2
}

.awlist117>li:before {
    content: counter(awlistcounter2_0) '.';
    counter-increment: awlistcounter2_0
}

.awlist118 {
    list-style: none;
    counter-reset: awlistcounter2_1
}

.awlist118>li:before {
    content: '3.'counter(awlistcounter2_1) '.';
    counter-increment: awlistcounter2_1
}

.awlist119 {
    list-style: none;
    counter-reset: awlistcounter2_1 1
}

.awlist119>li:before {
    content: '3.'counter(awlistcounter2_1) '.';
    counter-increment: awlistcounter2_1
}
</style>
