<script setup></script>
<template>
    <svg viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.1507 5.06824H6.07042C5.51592 5.06824 5.06641 5.51775 5.06641 6.07225V26.1525C5.06641 26.707 5.51592 27.1565 6.07042 27.1565H26.1507C26.7052 27.1565 27.1547 26.707 27.1547 26.1525V6.07225C27.1547 5.51775 26.7052 5.06824 26.1507 5.06824Z" stroke="#E1251B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22.1348 3.06018V7.07623" stroke="#E1251B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.0879 3.06018V7.07623" stroke="#E1251B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5.06641 11.0923H27.1547" stroke="#E1251B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M19.6257 19.1244H12.5977" stroke="#E1251B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16.1113 15.6104V22.6384" stroke="#E1251B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>